
import { updateInventoryCategoryField, getInventoryCategoryApi, deleteInventoryCategoryApi } from "apis/product.api";

import ListController from "pages/layout/ListController";
import { memo, useMemo, } from "react";

const InventoryCategoryListingController = function () {


    const columSchema = useMemo(
        () => [
            {
                id: "name",
                name: "name",
                align: "left",
                accessPath: ['name'],
                editable: true,
                inputType: "text",
                placeholder: "Enter name",
                key: "name",
                required: true,
                isSorted: true
            },

            {
                id: "priority",
                name: "priority",
                align: "left",
                accessPath: ['priority'],
                editable: true,
                inputType: "number",
                placeholder: "Enter priority",
                key: "priority",
                required: true,
                isSorted: true
            },

            {
                id: "Action",
                name: "Action",
                align: "left",
                isBlank: true,
                isSorted: false
            },
        ],
        []
    );
    return <ListController

        deleteFun={deleteInventoryCategoryApi}
        getFun={getInventoryCategoryApi}
        onFieldChange={updateInventoryCategoryField}
        columnSchema={columSchema}
        baseRoute="/inventorycategory/"
        title="Inventory Category"
    />
};
export default memo(InventoryCategoryListingController);
