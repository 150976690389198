
import { deleteProductCategory,getProductCategory,updateProductCategoryField } from "apis/product.api";
import ListController from "pages/layout/ListController";
import { memo, useMemo, } from "react";

const ProductCategroyListingController = function () {


    const columSchema = useMemo(
        () => [
            {
                id: "name",
                name: "name",
                align: "left",
                accessPath: ['name'],
                editable: true,
                inputType: "text",
                placeholder: "Enter name",
                key: "name",
                required: true,
                isSorted: true
            },

            {
                id: "priority",
                name: "priority",
                align: "left",
                accessPath: ['priority'],
                editable: true,
                inputType: "number",
                placeholder: "Enter priority",
                key: "priority",
                required: true,
                isSorted: true
            },

            {
                id: "Action",
                name: "Action",
                align: "left",
                isBlank: true,
                isSorted: false
            },
        ],
        []
    );
    return <ListController

        deleteFun={deleteProductCategory}
        getFun={getProductCategory}
        onFieldChange={updateProductCategoryField}
        columnSchema={columSchema}
        baseRoute="/product-category/"
        title="Product Category"
    />
};
export default memo(ProductCategroyListingController);
