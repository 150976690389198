



import {getProductCategory, getProductTypeApi, updateProductField, getProductColorApi, deleteProductApi, getProductApi } from "apis/product.api";



import ListController from "pages/layout/ListController";
import { memo, useMemo, } from "react";
import ProductRow from "./ProductRow";


const RoleListingController = function () {


    const columSchema = useMemo(
        () => [
            // {
            //     id: "title",
            //     name: "title",
            //     align: "left",
            //     accessPath: ['title'],
            //     editable: true,
            //     inputType: "text",
            //     placeholder: "Enter title",
            //     key: "title",
            //     required: true,
            //     isSorted: true
            // },

            {
                id: "name",
                align: "left",
                editable: true,
                accessPath: ['name'],
                isSorted: true,
                name: "Name",
                placeholder: "Enter Name",
                key: "name",
                inputType: "text",
                required: true,
                default: "",
            },

            {
                id: "mrp",
                align: "left",
                editable: true,
                accessPath: ['mrp'],
                isSorted: true,
                name: "MRP",
                placeholder: "Enter MRP",
                key: "mrp",
                inputType: "number",
                required: true,
                default: "",
            },

            {
                id: "type",
                align: "left",
                editable: true,
                accessPath: ['type', 'name'],
                isSorted: true,
                name: "type",
                placeholder: "Enter type",
                key: "type",



                inputType: "dropdown",
                lazyFun: getProductTypeApi,
                labelKey: "name",
                valueKey: "_id",
                required: true,



                default: "",
            },
            {
                id: "color",
                align: "left",
                editable: true,
                accessPath: ['color', 'name'],
                isSorted: true,
                name: "color",
                placeholder: "Enter color",
                key: "color",
                inputType: "dropdown",
                lazyFun: getProductColorApi,
                labelKey: "name",
                valueKey: "_id",
                required: true,



                default: "",
            },
            {
                id: "subProduct",
                name: "Sub Product",
                align: "left",
                accessPath: ['subProduct'],
                editable: true,
                inputType: "dropdown",
                placeholder: "Enter Sub product  ",
                key: "subProduct",
                required: true,
                type: "dropdown",
                lazyFun: getProductApi,
                labelKey: "name",
                multi: true,
                valueKey: "_id",

                isSorted: false
            },
            {
                id: "productCategory",
                name: "Product Category",
                align: "left",
                accessPath: ['productCategory','name'],
                editable: true,
                inputType: "dropdown",
                placeholder: "Enter Product Category",
                key: "productCategory",
                required: false,
                type: "dropdown",
                lazyFun: getProductCategory,
                labelKey: "name",
                
                valueKey: "_id",

                isSorted: false
            },

            {
                id: "Action",
                name: "Action",
                align: "left",
                isBlank: true,
                isSorted: false
            },
        ],
        []
    );
    return <ListController

        deleteFun={deleteProductApi}
        getFun={getProductApi}
        CustomRow={ProductRow}
        onFieldChange={updateProductField}
        columnSchema={columSchema}
        baseRoute="/product/"
        title="products"
    />
};
export default memo(RoleListingController);
