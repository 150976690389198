
import { updateSourcesField,getSourcesApi,deleteSourcesApi } from "apis/repairing.api";


import ListController from "pages/layout/ListController";
import { memo, useMemo, } from "react";

const RepairingSourceListingController = function () {


    const columSchema = useMemo(
        () => [
            {
                id: "name",
                name: "name",
                align: "left",
                accessPath: ['name'],
                editable: true,
                inputType: "text",
                placeholder: "Enter name",
                key: "name",
                required: true,
                isSorted: true
            },

            {
                id: "priority",
                name: "priority",
                align: "left",
                accessPath: ['priority'],
                editable: true,
                inputType: "number",
                placeholder: "Enter priority",
                key: "priority",
                required: true,
                isSorted: true
            },
            {
                id: "Action",
                name: "Action",
                align: "left",
                isBlank: true,
                isSorted: false
            },
        ],
        []
    );
    return <ListController

        deleteFun={deleteSourcesApi}
        getFun={getSourcesApi}
        onFieldChange={updateSourcesField}
        columnSchema={columSchema}
        baseRoute="/repairing-source/"
        title="Repairing source"
    />
};
export default memo(RepairingSourceListingController);
