

import { getModulePermissionsApi, updateModulePermissionsField, deleteModulePermissionsApi } from "apis/permission.api";
import { getRolesApi } from "apis/roles.api";
import ListController from "pages/layout/ListController";
import { memo, useMemo, } from "react";
import PermissionRow from "./PermissionRow";

const PermissionListingController = function () {


    const columSchema = useMemo(
        () => [
            {
                id: "title",
                name: "title",
                align: "left",
                accessPath: ['title'],
                editable: true,
                inputType: "text",
                placeholder: "Enter title",
                key: "title",
                required: true,
                isSorted: true
            },

            {
                id: "allowed",
                name: "allowed",
                align: "left",
                accessPath: ['allowed'],
                editable: true,
                inputType: "dropdown",
                placeholder: "Enter allowed roles ",
                key: "allowed",
                required: true,
                type: "dropdown",
                lazyFun: getRolesApi,
                labelKey:"title",
                multi:true,
                valueKey:"rolecode",
                
                isSorted: false
            },

            {
                id: "Action",
                name: "Action",
                align: "left",
                isBlank: true,
                isSorted: false
            },
        ],
        []
    );
    return <ListController
        CustomRow={PermissionRow}
        deleteFun={deleteModulePermissionsApi}
        getFun={getModulePermissionsApi}
        onFieldChange={updateModulePermissionsField}
        columnSchema={columSchema}
        baseRoute="/permission/"
        title="Module Permissions"
    />
};
export default memo(PermissionListingController);
