export const avialableGender = {
  MALE: 0,
  FEMALE: 1,
  BOTH: 2,
};
export const roles = {
  SUPER_ADMIN: 0,
  ADMIN_ROLE: 1,
  SALON_PARTNER: 2,
  CUSTOMER: 3
};
export const sallonStatus = {

};
export const permissions = {
  ADMIN_PANEL_USERS: 'ADMIN_PANEL_USERS'
}
export 
const repairingStatuses = {
    
    INITIALIZE: 'initialize',
    DEVICE_ARRIVED:"device_arrived",
    CRM_EVOLUTION:"crm_evolution",
    CUSTOMER_DECLAINED:"customer_declained",
    MONEY_RECIEVED:"money_received",
    WAIT_FOR_MONEY:"wait_for_money",
    UNDER_REPAIRING:"under_repairing",
    CHARGABLE:"chargable",
    REPLACE_REQUIRED:"replace_required",
    COMPLETED: 'complete',
    REPLACED: 'replaced',

    DISAPTCH_AND_FINISHED:"dispatch_and_finished",
    REPAIR: 'repaired',
    MONEY: 'money',
    COMMON:"common"

}

export default {
  avialableGender  
}
