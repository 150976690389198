import * as React from "react";
import Button from "@mui/material/Button";

import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import PropTypes from "prop-types";
import useModal from "hooks/useModal";
import MDButton from "components/MDButton";
import { useState } from "react";
import useAlert from "hooks/useAlert";
import lang from "utils/lang";

function DeleteAlert ({ onDelete }) {
  const { closeModal } = useModal();
  const alert = useAlert();
  const [loading, setLoading] = useState(false);
  const deleteFunc = async () => {
    try {
      setLoading(true);
      await onDelete();
      closeModal()
      setLoading(false);
    } catch (e) {      
      alert.showAlert(lang("unknown_error"), lang("something_wrong"), "");
      setLoading(false);
    }
  };
  return (
    <>
      <DialogTitle>Are you sure to want to delete?</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-slide-description'>
          Deleted content will not be revrert. so make sure before delete!
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <MDButton disabled={loading} variant='text' color='info' onClick={closeModal}>
          CANCLE
        </MDButton>
        <MDButton disabled={loading} variant='contained' onClick={deleteFunc} color='error'>
          DELETE
        </MDButton>
      </DialogActions>
    </>
  );
}
export default DeleteAlert;
DeleteAlert.propTypes = {
  onDelete: PropTypes.func.isRequired,
};
