import PropTypes from "prop-types";

import useApiCall from "hooks/useApiCall";
import useControlledField from "hooks/useControlledField";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import ListUi from "./ListUi";

const ListController = function ({ deleteFun, getFun, columnSchema, baseRoute, title, onFieldChange, CustomRow }) {
  const navigate = useNavigate();
  const initialParams = useMemo(() => {
    const obj = {
      pageSize: 10,
      pageNo: 1,
      search: "",
      sort: null,
      is_admin: true,
    };
    return obj;
  }, []);
  const apiCallFun = useApiCall();

  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [data, setData] = useState([]);

  const [params, handleParams] = useControlledField(initialParams);

  const fetchData = useCallback(async () => {
    setLoading(true);
    // const response = await ;
    if (!loading) {
      await apiCallFun(
        async () => {
          const response = await getFun(params);
          return response;
        },
        res => {
          setData(res.result);
          setTotal(res.total);
          setLoading(false);
        },
        () => {
          setLoading(false);
        }
      );
    }
  }, [params, loading, setLoading, setTotal]);

  const onDelete = useCallback(
    async id => {
      await apiCallFun(
        async () => {
          const response = await deleteFun({ id });
          await fetchData();
          return response;
        },
        res => { },
        () => { }
      );
    },

    []
  );
  //

  // demo

  // {
  //      isBlank:false
  // id: "name", //this name willl be used for sorting
  //  name: "name", //this will be used for column heading
  //   align: "left",
  //  accessPath: ['name'],
  //  isSorted: true     
  //  editable: true,
  //  inputType: "text", "radio","dropdown","checkbox"
  //  required: true,
  //  placeholder: "Select gender",//will be used while edit
  //  key: "gender",//it will be used as field name
  //  type: "dropdown",
  //  lazyFun:function, // this will used when async dropdown is used
  //  lableKey:"", // this will used when async dropdown is used and label of doropdown wil be fetched by this key
  //  valueKey:"", // this will used when async dropdown is used and value of doropdown wil be fetched by this key
  //  default: avialableGender.BOTH,
  //  option: Object.keys(avialableGender).map(key => {
  // const obj = { label: key, value: avialableGender[key] };
  //    return obj;
  //     }),
  //  enum: Object.values(avialableGender),
  // }
  const columns = useMemo(
    () => columnSchema,
    []
  );

  const onChangeParams = useCallback(
    dataTableParams => {
      handleParams("set", Object.keys(dataTableParams), Object.values(dataTableParams));
    },
    [handleParams]
  );

  useEffect(() => {
    fetchData();
  }, [params]);

  const onAddButtonClick = useCallback(() => {
    navigate(`${baseRoute}add`);
  }, []);
  const onUpdateButtonClick = useCallback(id => {
    navigate(`${baseRoute}update/${id}`);
  }, []);

  const onBooleanChanges = useCallback(async (field, value, id) => {
    await apiCallFun(
      async () => {
        const response = await onFieldChange({ [field]: value, id });
        await fetchData();
        return response;
      },
      res => { },
      () => { }
    );
  }, []);
  return (
    <ListUi
      onBooleanChanges={onBooleanChanges}
      onDelete={onDelete}
      onUpdateButtonClick={onUpdateButtonClick}
      title={title}
      onChangeParams={onChangeParams}
      loading={loading}
      total={total}
      CustomRow={CustomRow}
      onAddButtonClick={onAddButtonClick}
      initialParams={initialParams}
      columns={columns}
      rows={data}
    />
  );
};
export default ListController;
ListController.defaultProps = {
  CustomRow: null
}
ListController.propTypes = {
  CustomRow: PropTypes.node,
  deleteFun: PropTypes.func.isRequired,
  getFun: PropTypes.func.isRequired,
  baseRoute: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  onFieldChange: PropTypes.func.isRequired,
  columnSchema: PropTypes.arrayOf(PropTypes.any).isRequired
};