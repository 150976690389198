
import { updateLeadStatusField, getLeadStatusApi, deleteLeadStatusApi } from "apis/lead.api";

import ListController from "pages/layout/ListController";
import { memo, useMemo, } from "react";

const LeadStatusListingController = function () {


    const columSchema = useMemo(
        () => [
            {
                id: "name",
                name: "name",
                align: "left",
                accessPath: ['name'],
                editable: true,
                inputType: "text",
                placeholder: "Enter name",
                key: "name",
                required: true,
                isSorted: true
            },

            {
                id: "priority",
                name: "priority",
                align: "left",
                accessPath: ['priority'],
                editable: true,
                inputType: "number",
                placeholder: "Enter priority",
                key: "priority",
                required: true,
                isSorted: true
            },

            {
                id: "parentId",
                align: "left",
                editable: true,
                accessPath: ['parentId', 'name'],
                isSorted: true,
                name: "parent Status",
                placeholder: "Enter parent Status",
                key: "parentId",
                inputType: "dropdown",
                lazyFun: getLeadStatusApi,
                labelKey: "name",
                valueKey: "_id",
                required: true,

                default: "",
            },

            {
                id: "Action",
                name: "Action",
                align: "left",
                isBlank: true,
                isSorted: false
            },
        ],
        []
    );
    return <ListController

        deleteFun={deleteLeadStatusApi}
        getFun={getLeadStatusApi}
        onFieldChange={updateLeadStatusField}
        columnSchema={columSchema}
        baseRoute="/leadstatus/"
        title="Lead Status"
    />
};
export default memo(LeadStatusListingController);
