
import { getRolesApi } from "apis/roles.api";

import { addUserApi } from "apis/user.api";
import FormController from "pages/layout/FormController";
import { memo, useMemo, } from "react";



const RolesFormController = function () {


    const fieldSchema = useMemo(
        () => [
            {
                title: "Name",
                placeholder: "Enter Name",
                key: "name",
                type: "text",
                required: true,
                default: "",
            },
            {
                title: "password",
                placeholder: "Enter password",
                key: "password",
                type: "text",
                required: true,
                default: "",
            },
            {
                title: "role",
                placeholder: "Enter role",
                key: "role",
                type: "dropdown",
                lazyFun: getRolesApi,
                labelKey: "title",
                valueKey: "rolecode",
                required: true,
                default: "",
            },
            {
                title: "email",
                placeholder: "Enter email",
                key: "email",
                type: "email",
                required: true,
                default: "",
            },
            {
                title: "phone No",
                placeholder: "Enter phone No",
                key: "phoneNo",
                type: "text",
                required: true,
                default: "",
            },
            {
                title: "company Name",
                placeholder: "Enter company Name",
                key: "companyName",
                type: "text",
                default: "",
            },
            {
                title: "gst No",
                placeholder: "Enter gst No",
                key: "gstNo",
                type: "text",
                default: "",
            },
            {
                title: "territory",
                placeholder: "Enter territory",
                key: "territory",
                type: "text",
                default: "",
            },
            {
                title: "address",
                placeholder: "Enter address",
                key: "address",
                type: "text",
                mul:true,
                default: "",
            },
            {
                title: "Bank Acc No",
                placeholder: "Enter bank No",
                key: "bankNo",
                type: "text",
                default: "",
            },
            {
                title: "Bank IFSC",
                placeholder: "Enter bank IFSC",
                key: "bankIFSC",
                type: "text",
                default: "",
            },
            {
                title: "Bank Name",
                placeholder: "Enter bankName",
                key: "bankName",
                type: "text",
                default: "",
            },
        ],
        []
    );
    return <FormController

        routeForRedirect="/user/"
        title="user"
        inputFiedsSchema={fieldSchema}
        addFun={addUserApi}
        getByIdFun={() => { }}
        updateFun={() => { }}

    />
};
export default memo(RolesFormController);
