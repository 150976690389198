

import { getRolesApi } from "apis/roles.api";
import { getUserApi, updateUserField, deleteUserApi } from "apis/user.api";

import ListController from "pages/layout/ListController";
import { memo, useMemo, } from "react";
import { sallonStatus } from "utils/constants";

const RoleListingController = function () {


    const columSchema = useMemo(
        () => [
            // {
            //     id: "title",
            //     name: "title",
            //     align: "left",
            //     accessPath: ['title'],
            //     editable: true,
            //     inputType: "text",
            //     placeholder: "Enter title",
            //     key: "title",
            //     required: true,
            //     isSorted: true
            // },

            {
                id: "name",
                align: "left",
                editable: true,
                accessPath: ['name'],
                isSorted: true,
                name: "Name",
                placeholder: "Enter Name",
                key: "name",
                inputType: "text",
                required: true,
                default: "",
            },

            {
                id: "role",
                align: "left",
                editable: true,
                accessPath: ['role', 'title'],
                isSorted: true,
                name: "role",
                placeholder: "Enter role",
                key: "role",
                inputType: "dropdown",
                lazyFun: getRolesApi,
                labelKey: "title",
                valueKey: "rolecode",

                // default: sallonStatus.OPEN,
                // option: Object.keys(sallonStatus).map(key => {
                //   const obj = { label: key, value: sallonStatus[key] };
                //   return obj;
                // }),
                // enum: Object.values(sallonStatus),
                required: true,
                default: "",
            },
            {
                id: "email",
                align: "left",
                editable: true,
                accessPath: ['email'],
                isSorted: true,
                name: "email",
                placeholder: "Enter email",
                key: "email",
                inputType: "email",
                required: true,
                default: "",
            },
            {
                id: "phoneNo",
                align: "left",
                editable: true,
                accessPath: ['phoneNo'],
                isSorted: true,
                name: "phone No",
                placeholder: "Enter phone No",
                key: "phoneNo",
                inputType: "text",
                required: true,
                default: "",
            },
            {
                id: "companyName",
                align: "left",
                editable: true,
                accessPath: ['companyName'],
                isSorted: true,
                name: "company Name",
                placeholder: "Enter company Name",
                key: "companyName",
                inputType: "text",
                default: "",
            },
            {
                id: "gstNo",
                align: "left",
                editable: true,
                accessPath: ['gstNo'],
                isSorted: true,
                name: "gst No",
                placeholder: "Enter gst No",
                key: "gstNo",
                inputType: "text",
                default: "",
            },
            
            {
                id: "territory",
                align: "left",
                editable: true,
                accessPath: ['territory'],
                isSorted: true,
                name: "territory",
                placeholder: "Enter territory",
                key: "territory",
                inputType: "text",
                default: "",
            },
            {
                id: "address",
                align: "left",
                editable: true,
                accessPath: ['address'],
                isSorted: true,
                name: "address",
                placeholder: "Enter address",
                key: "address",
                inputType: "text",
                default: "",
            },
            {
                id: "bankName",
                align: "left",
                editable: true,
                accessPath: ['bankName'],
                isSorted: true,
                name: "Bank Name",
                placeholder: "Enter Bank Name",
                key: "bankName",
                inputType: "text",
                default: "",
            },
            {
                id: "bankIFSC",
                align: "left",
                editable: true,
                accessPath: ['bankIFSC'],
                isSorted: true,
                name: "bank IFSC",
                placeholder: "Enter bank IFSC",
                key: "bankIFSC",
                inputType: "text",
                default: "",
            },
            {
                id: "bankNo",
                align: "left",
                editable: true,
                accessPath: ['bankNo'],
                isSorted: true,
                name: "bank Acc No",
                placeholder: "Enter Bank Acc No",
                key: "bankNo",
                inputType: "text",
                default: "",
            },


            {
                id: "Action",
                name: "Action",
                align: "left",
                isBlank: true,
                isSorted: false
            },
        ],
        []
    );
    return <ListController

        deleteFun={deleteUserApi}
        getFun={getUserApi}
        onFieldChange={updateUserField}
        columnSchema={columSchema}
        baseRoute="/user/"
        title="Users"
    />
};
export default memo(RoleListingController);
