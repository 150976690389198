import { resetTokenApi } from "apis/auth.api";
import { accessToken, refreshToken, logOut } from "utils/helper";
import lang from "utils/lang";
import useAlert from "./useAlert";

const useApiCall = () => {
  const alert = useAlert();

  const apicallFun = async (
    apiCall = async () => {},
    onSuccess = async (data) => {},
    onFailure = async (erorr) => {}
  ) => {
    try {
      const response = await apiCall();

      if (response.status === 1) {
        const successFun = await onSuccess(response.data);
        return successFun;
      }
      switch (response.code) {
        case 401:
          {
            const resetTokenResponse = await resetTokenApi();
            if (resetTokenResponse.status === 1) {
              accessToken.set(resetTokenResponse.data.accessToken);
              refreshToken.set(resetTokenResponse.data.refreshToken);
              return apicallFun(apiCall, onSuccess, onFailure);
            }

            alert.showAlert(
              lang("session_expire_error"),
              lang("session_expire_error_description"),
              ""
            );
            logOut();
            window.location.reload();
          }
          break;

        case 400:
          alert.showAlert(
            lang("validation_error"),
            response.data && Array.isArray(response.data) && response.data[0]
              ? lang(response.data[0].msg)
              : lang("validation_error_default"),
            ""
          );
          break;
        case 404:
          alert.showAlert(lang("not_found_error"), lang("not_found_error_description"), "");
          break;
        case 500:
        /* falls through */
        case 501:
          alert.showAlert(lang("server_error"), lang("server_error_description"), "");
          break;
        case 502:
        /* falls through */
        case 503:
        /* falls through */
        case 504:
          alert.showAlert(lang("bad_gateway_error"), lang("not_found_error_description"), "");
          break;
        case 403:
          alert.showAlert(lang("restriction_error"), lang("restriction_error_description"), "");
          break;
        default:
          alert.showAlert(lang("unknown_error"), lang("something_wrong"), "");
          break;
      }

      const failureFun = await onFailure(response.data);
      return failureFun;
    } catch (e) {
      alert.showAlert(lang("unknown_error"), lang("something_wrong"), "");

      const failureFun = await onFailure(e);
      return failureFun;
    }
  };
  return apicallFun;
};
export default useApiCall;
