

import { addRepairingStatusApi } from "apis/repairing.api";
import { getRolesApi } from "apis/roles.api";

import FormController from "pages/layout/FormController";
import { memo, useMemo, } from "react";
import { repairingStatuses } from "utils/constants";



const RepairingFormController = function () {


    const fieldSchema = useMemo(
        () => [
            {
                title: "Name",
                placeholder: "Enter name",
                key: "name",
                type: "text",
                required: true,
                default: "",
            },
            {
                title: "Priority",
                placeholder: "Enter priority",
                key: "priority",
                type: "number",
                required: true,
                default: "",
            },
            {
                title: "Description",
                placeholder: "Enter description",
                key: "description",
                type: "text",
                required: true,
                default: "",
            },

            {
                title: "allowed",
                placeholder: "Enter allowed roles ",
                key: "allowed",
                type: "dropdown",
                lazyFun: getRolesApi,
                labelKey: "title",
                multi: true,
                valueKey: "rolecode",
                required: true,
                default: "",
            },
            {
                title: "In Pending",
                placeholder: "Enter roles for status in Pending ",
                key: "inPending",
                type: "dropdown",
                lazyFun: getRolesApi,
                labelKey: "title",
                multi: true,
                valueKey: "rolecode",
                required: true,
                default: "",
            },
            {
                title: "In Completed",
                placeholder: "Enter roles for status in completed  ",
                key: "inCompleted",
                type: "dropdown",
                lazyFun: getRolesApi,
                labelKey: "title",
                multi: true,
                valueKey: "rolecode",
                required: true,
                default: "",
            },

            {
                title: "Motive",
                placeholder: "Enter roles for status motive  ",
                key: "isFor",
                type: "dropdown",
                default: repairingStatuses.COMMON,
                option: Object.keys(repairingStatuses).map(key => {
                    const obj = { label: key, value: repairingStatuses[key] };
                    return obj;
                }),
                labelKey: "label",
                valueKey: "value",
                required: true,                
            },

            {
                title: "notifyTo",
                placeholder: "Enter roles to Be notify",
                key: "notifyTo",
                type: "dropdown",
                lazyFun: getRolesApi,
                labelKey: "title",
                multi: true,
                valueKey: "rolecode",
                required: true,
                default: "",
            },

        ],
        []
    );
    return <FormController

        routeForRedirect="/repairing-status/"
        title=" Repairing Status"
        inputFiedsSchema={fieldSchema}
        addFun={addRepairingStatusApi}
        getByIdFun={() => { }}
        updateFun={() => { }}

    />
};
export default memo(RepairingFormController);
