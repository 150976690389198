import { signInApi } from "apis/auth.api";
import useAlert from "hooks/useAlert";
import useApiCall from "hooks/useApiCall";
import useUser from "hooks/useUser";
import { permissions } from "utils/constants";


import { validatePhone, validateEmail } from "utils/helper";

import lang from "utils/lang";
import useControlledField from "../../../hooks/useControlledField";
import SignInUi from "./SignInUi";

function SignInController() {
  const [state, handleState] = useControlledField({ email: "", password: "" });
  const alert = useAlert();
  const apiCallFun = useApiCall();
  const { loading, login } = useUser();
  const onSubmit = async e => {
    e.preventDefault();



    if (!(state.email && state.email !== '')) {
      alert.showAlert(lang('validation_error'), lang('email_required'), '')
      return 0
    }

    if (!(validateEmail(state.email) || validatePhone(state.email))) {
      alert.showAlert(lang('validation_error'), lang('email_invalid'), '')
      return 0
    }
    if (!(state.password && state.password !== "")) {
      alert.showAlert(lang("validation_error"), lang("password_required"), "");
      return 0;
    }

    await apiCallFun(
      async () => {
        const response = await signInApi({ ...state });
        return response;
      },
      async data => {


        if (data.permissions.includes(permissions.ADMIN_PANEL_USERS)) {
          login(data);

        } else {
          alert.showAlert(lang('validation_error'), lang('no_admin'), '')

        }

      },
      async error => { }
    );
    return 0;
  };

  return <SignInUi loading={loading} onSubmit={onSubmit} state={state} handleState={handleState} />;
}
export default SignInController;
