


import { updateRepairingStatusField ,deleteRepairingStatusApi,getRepairingStatusApi} from "apis/repairing.api";

import { getRolesApi } from "apis/roles.api";
import ListController from "pages/layout/ListController";
import { memo, useMemo, } from "react";
import RepairingStatusRow from "./RepairingStatusRow";


const RepairingStatusListingController = function () {


    const columSchema = useMemo(
        () => [
            {
                id: "name",
                name: "name",
                align: "left",
                accessPath: ['name'],
                editable: true,
                inputType: "text",
                placeholder: "Enter name",
                key: "name",
                required: true,
                isSorted: true
            },
            
            {
                id: "description",
                name: "description",
                align: "left",
                accessPath: ['description'],
                editable: true,
                inputType: "text",
                placeholder: "Enter description",
                key: "description",
                required: true,
                isSorted: true
            },
            {
                id: "priority",
                name: "priority",
                align: "left",
                accessPath: ['priority'],
                editable: true,
                inputType: "number",
                placeholder: "Enter priority",
                key: "priority",
                required: true,
                isSorted: true
            },
            {
                id: "allowed",
                name: "allowed",
                align: "left",
                accessPath: ['allowed'],
                editable: true,
                inputType: "dropdown",
                placeholder: "Enter allowed roles ",
                key: "allowed",
                required: true,
                type: "dropdown",
                lazyFun: getRolesApi,
                labelKey:"title",
                multi:true,
                valueKey:"rolecode",
                
                isSorted: false
            },


            {
                id: "notifyTo",
                name: "notify To",
                align: "left",
                accessPath: ['notifyTo'],
                editable: true,
                inputType: "dropdown",
                placeholder: "Enter notifyTo roles ",
                key: "notifyTo",
                required: true,
                type: "dropdown",
                lazyFun: getRolesApi,
                labelKey:"title",
                multi:true,
                valueKey:"rolecode",
                
                isSorted: false
            },
            {
                id: "inCompleted",
                name: "in Completed",
                align: "left",
                accessPath: ['inCompleted'],
                editable: true,
                inputType: "dropdown",
                placeholder: "Enter inCompleted roles ",
                key: "inCompleted",
                required: true,
                type: "dropdown",
                lazyFun: getRolesApi,
                labelKey:"title",
                multi:true,
                valueKey:"rolecode",
                
                isSorted: false
            },
            {
                id: "inPending",
                name: "in Pending",
                align: "left",
                accessPath: ['inPending'],
                editable: true,
                inputType: "dropdown",
                placeholder: "Enter inPending roles ",
                key: "inPending",
                required: true,
                type: "dropdown",
                lazyFun: getRolesApi,
                labelKey:"title",
                multi:true,
                valueKey:"rolecode",
                
                isSorted: false
            },

            {
                id: "isFor",
                name: "Motive",
                align: "left",
                accessPath: ['isFor'],
                
                
                key: "isFor",
                
                
            },
            {
                id: "step",
                name: "step",
                align: "left",
                accessPath: ['step'],
                editable: true,
                inputType: "number",
                placeholder: "Enter step",
                key: "step",
                required: true,
                isSorted: true
            },
            {
                id: "notifyCustomer",
                name: "notifyCustomer",
                align: "left",
                accessPath: ['notifyCustomer'],
                editable: true,
                inputType: "checkbox",
                placeholder: " Is notifyCustomer",
                key: "notifyCustomer",  
            }
            ,
            {
                id: "Action",
                name: "Action",
                align: "left",
                isBlank: true,
                isSorted: false
            },
        ],
        []
    );
    return <ListController
        CustomRow={RepairingStatusRow}
        deleteFun={deleteRepairingStatusApi}
        getFun={getRepairingStatusApi}
        onFieldChange={updateRepairingStatusField}
        columnSchema={columSchema}
        baseRoute="/repairing-status/"
        title="Module Repairing Statuses"
    />
};
export default memo(RepairingStatusListingController);
