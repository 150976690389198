const inputFieldReducer = (state, action) => {
  switch (action.type) {
    case "CHANGE_INPUT": {
      const oldState = { ...state };
      oldState[action.key] = action.value;
      return oldState;
    }
    case "CHANGE_WHOLE_STATE": {
      return action.value;
    }
    default:
      return { ...state };
  }
};
export default inputFieldReducer;
