import PropTypes from "prop-types";
import { Card, Grid, Icon, TableRow } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Datatable from "components/custom/Datatable";
import { useCallback } from "react";
import MDButton from "components/MDButton";
import Row from "./Row";

const ListUi = function ({
  title,
  columns,
  onDelete,
  rows,
  initialParams,
  onChangeParams,
  loading,
  total,
  CustomRow,
  onAddButtonClick,
  onUpdateButtonClick,
  onBooleanChanges
}) {

  const renderRows = useCallback(
    (row, key, currentParams) => {
      if (CustomRow) {
        return <CustomRow columns={columns} onBooleanChanges={onBooleanChanges} onUpdateButtonClick={onUpdateButtonClick} onDelete={onDelete} data={row} key={key} currentParams={currentParams} />
      }
      return <Row columns={columns} onBooleanChanges={onBooleanChanges} onUpdateButtonClick={onUpdateButtonClick} onDelete={onDelete} data={row} key={key} currentParams={currentParams} />

    },
    [rows]
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant='gradient'
                bgColor='info'
                borderRadius='lg'
                coloredShadow='info'
                display="flex" justifyContent="space-between" alignItems="center"
              >
                <MDTypography variant='h6' color='white'>
                  {title}
                </MDTypography>
                <MDButton onClick={onAddButtonClick} variant='contained' color='white' >
                  <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                  &nbsp;add {title}
                </MDButton>
              </MDBox>
              <MDBox pt={3}>
                {/* <DataTable
                  table={{ columns, rows }}
                  isSorted="true"
                  entriesPerPage='true'
                  canSearch='true'                  
                  showTotalEntries="true"
                  noEndBorder
                /> */}
                <Datatable
                  columns={columns}
                  rows={rows}
                  loading={loading}
                  total={total}
                  initialParams={initialParams}
                  renderRow={renderRows}
                  onChangeParams={onChangeParams}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
};
export default ListUi;
ListUi.defaultProps = {
  CustomRow: null
}
ListUi.propTypes = {
  initialParams: PropTypes.objectOf(PropTypes.any).isRequired,
  title: PropTypes.string.isRequired,
  onChangeParams: PropTypes.func.isRequired,
  columns: PropTypes.arrayOf(PropTypes.any).isRequired,
  rows: PropTypes.arrayOf(PropTypes.any).isRequired,
  loading: PropTypes.bool.isRequired,
  total: PropTypes.number.isRequired,
  onDelete: PropTypes.func.isRequired,
  onAddButtonClick: PropTypes.func.isRequired,
  CustomRow: PropTypes.node,
  onUpdateButtonClick: PropTypes.func.isRequired,
  onBooleanChanges: PropTypes.func.isRequired
};
