import axios from "axios";
import { getHeaders } from "utils/helper";
import endpoints from "./endpoints";

export const getRepairingStatusApi = async params => {
    const callResponse = await axios({
        url: endpoints.ticketStatus,
        method: "get",
        headers: getHeaders(),
        params,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};

export const deleteRepairingStatusApi = async data => {
    const callResponse = await axios({
        url: endpoints.ticketStatus,
        method: "DELETE",
        headers: getHeaders(),
        data,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};
export const addRepairingStatusApi = async data => {
    const callResponse = await axios({
        url: endpoints.ticketStatus,
        method: "POST",        
        headers: { 'Content-Type': 'application/json', ...getHeaders() },
        data: JSON.stringify(
            {
                allowed: data.get('allowed').split(','),
                notifyTo: data.get('notifyTo').split(','),
                inPending: data.get('inPending').split(','),
                inCompleted: data.get('inCompleted').split(','),
                name: data.get('name'),
                priority: data.get('priority'),
                description: data.get('description'),
                isFor: data.get('isFor'),

                
            }
        ),
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};

export const updateRepairingStatusField = async data => {
    const callResponse = await axios({
        url: endpoints.ticketStatus,
        method: "PUT",
        headers: { 'Content-Type': 'application/json', ...getHeaders() },
        
        data,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};


export const getTicketProblemsApi = async params => {
    const callResponse = await axios({
        url: endpoints.ticketProblems,
        method: "get",
        headers: getHeaders(),
        params,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};

export const deleteTicketProblemsApi = async data => {
    const callResponse = await axios({
        url: endpoints.ticketProblems,
        method: "DELETE",
        headers: getHeaders(),
        data,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};
export const addTicketProblemsApi = async data => {
    const callResponse = await axios({
        url: endpoints.ticketProblems,
        method: "POST",
        headers: getHeaders(),
        // headers: { "Content-Type": "multipart/form-data", ...getHeaders() },
        data,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};

export const updateTicketProblemsField = async data => {
    const callResponse = await axios({
        url: endpoints.ticketProblems,
        method: "PUT",
        headers: getHeaders(),
        data,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};


export const getSourcesApi = async params => {
    const callResponse = await axios({
        url: endpoints.sourceOfSales,
        method: "get",
        headers: getHeaders(),
        params,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};

export const deleteSourcesApi = async data => {
    const { id, ...remained } = data
    const callResponse = await axios({
        url: endpoints.sourceOfSales + id,
        method: "DELETE",
        headers: getHeaders(),
        data: remained,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};
export const addSourcesApi = async data => {
    const callResponse = await axios({
        url: endpoints.sourceOfSales,
        method: "POST",
        headers: getHeaders(),
        // headers: { "Content-Type": "multipart/form-data", ...getHeaders() },
        data,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};

export const updateSourcesField = async data => {
    const { id, ...remained } = data
    const callResponse = await axios({
        url: endpoints.sourceOfSales + id,
        method: "PUT",
        headers: getHeaders(),
        data: remained,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};

