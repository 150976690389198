
import { addModulePermissionsApi } from "apis/permission.api";
import { getRolesApi } from "apis/roles.api";

import FormController from "pages/layout/FormController";
import { memo, useMemo, } from "react";



const InventoryCategoryFormController = function () {


    const fieldSchema = useMemo(
        () => [
            {
                title: "Title",
                placeholder: "Enter title",
                key: "title",
                type: "text",
                required: true,
                default: "",
            },
            {
                title: "forFrontEnd",
                placeholder: "Enter forFrontEnd",
                key: "forFrontEnd",
                type: "checkbox",
                required: true,
                default: "",
            },
            {
                title: "allowed",
                placeholder: "Enter allowed roles ",
                key: "allowed",
                type: "dropdown",
                lazyFun: getRolesApi,
                labelKey:"title",
                multi:true,
                valueKey:"rolecode",
                required: true,
                default: "",
            },
        ],
        []
    );
    return <FormController

        routeForRedirect="/permission/"
        title=" Module Permissions"
        inputFiedsSchema={fieldSchema}
        addFun={addModulePermissionsApi}
        getByIdFun={() => { }}
        updateFun={() => { }}

    />
};
export default memo(InventoryCategoryFormController);
