
import { getTicketProblemsApi,deleteTicketProblemsApi,updateTicketProblemsField } from "apis/repairing.api";


import ListController from "pages/layout/ListController";
import { memo, useMemo, } from "react";

const RepairingProblemsController = function () {


    const columSchema = useMemo(
        () => [
            {
                id: "name",
                name: "name",
                align: "left",
                accessPath: ['name'],
                editable: true,
                inputType: "text",
                placeholder: "Enter name",
                key: "name",
                required: true,
                isSorted: true
            },

            {
                id: "priority",
                name: "priority",
                align: "left",
                accessPath: ['priority'],
                editable: true,
                inputType: "number",
                placeholder: "Enter priority",
                key: "priority",
                required: true,
                isSorted: true
            },
            {
                id: "inputField",
                name: "inputField",
                align: "left",
                accessPath: ['inputField'],
                editable: true,
                inputType: "checkbox",
                placeholder: " Is inputField",
                key: "inputField",                
            },
            {
                id: "Action",
                name: "Action",
                align: "left",
                isBlank: true,
                isSorted: false
            },

        ],
        []
    );
    return <ListController

        deleteFun={deleteTicketProblemsApi}
        getFun={getTicketProblemsApi}
        onFieldChange={updateTicketProblemsField}
        columnSchema={columSchema}
        baseRoute="/repairing-problems/"
        title="Repairing Problems"
    />
};
export default memo(RepairingProblemsController);
