
import { addLeadStatusApi,getLeadStatusApi } from "apis/lead.api";
import FormController from "pages/layout/FormController";
import { memo, useMemo, } from "react";



const LeadStatusFormController = function () {


    const fieldSchema = useMemo(
        () => [
            {
                title: "Name",
                placeholder: "Enter name",
                key: "name",
                type: "text",
                required: true,
                default: "",
            },
            {
                title: "Priority",
                placeholder: "Enter priority",
                key: "priority",
                type: "number",
                required: true,
                default: "",
            },

            {
                title: "Parent Status",
                placeholder: "Enter parentId  ",
                key: "parentId",
                type: "dropdown",
                lazyFun: getLeadStatusApi,
                labelKey:"name",                
                valueKey:"_id",
                required: false,
                default: "",
            },
        ],
        []
    );
    return <FormController

        routeForRedirect="/leadstatus/"
        title="lead Status"
        inputFiedsSchema={fieldSchema}
        addFun={addLeadStatusApi}
        getByIdFun={() => { }}
        updateFun={() => { }}

    />
};
export default memo(LeadStatusFormController);
