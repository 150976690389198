
import { addRolesApi } from "apis/roles.api";
import FormController from "pages/layout/FormController";
import { memo, useMemo, } from "react";



const RolesFormController = function () {


    const fieldSchema = useMemo(
        () => [
            {
                title: "Title",
                placeholder: "Enter Role Title",
                key: "title",
                type: "text",
                required: true,
                default: "",
            },
            {
                title: "Role Code",
                placeholder: "Enter RoleCode",
                key: "rolecode",
                type: "number",
                required: true,
                default: "",
            },
        ],
        []
    );
    return <FormController

        routeForRedirect="/roles/"
        title="roles"
        inputFiedsSchema={fieldSchema}
        addFun={addRolesApi}
        getByIdFun={() => { }}
        updateFun={() => { }}

    />
};
export default memo(RolesFormController);
