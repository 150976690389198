import PropTypes from "prop-types";
import Sidenav from "examples/Sidenav";
import { Navigate, Route, Routes } from "react-router-dom";
import routes from "routes";
import useUser from "hooks/useUser";

import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import { Card, CircularProgress, Grid } from "@mui/material";
import SignInController from "pages/auth/signin/SignInController";

function Main ({ layout, color, brand, onMouseEnter, onMouseLeave }) {
  const getRoutes = allRoutes =>
    allRoutes.map(route => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });
  const { loading, isLoggedIn } = useUser();

  if (loading) {
    return (
      <BasicLayout>
        <Grid
          container
          spacing={0}
          direction='column'
          alignItems='center'
          justifyContent='center'
          style={{ minHeight: "100vh" }}
        >
          <Grid item xs={3}>
            <CircularProgress color='primary' />
          </Grid>
        </Grid>
      </BasicLayout>
    );
  }
  return (
    <>
      {layout === "dashboard" && (
        <>
          <Sidenav
            color={color}
            brand={brand}
            brandName='WeHear Admin'
            routes={routes}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          />
          {/* <Configurator /> */}
          {/* {configsButton} */}
        </>
      )}

      <Routes>
        {isLoggedIn && getRoutes(routes)}
        {!isLoggedIn && <Route path="/sign-in" element={<SignInController />} />}
        {isLoggedIn ? <Route path='*' element={<Navigate to='/dashboard' />} />: <Route path='*' element={<Navigate to='/sign-in' />} />}
      </Routes>
    </>
  );
}
export default Main;
Main.propTypes = {
  layout: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  brand: PropTypes.string.isRequired,
  onMouseEnter: PropTypes.func.isRequired,

  onMouseLeave: PropTypes.func.isRequired,
};
