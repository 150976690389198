
import { addProductColorApi } from "apis/product.api";
import FormController from "pages/layout/FormController";
import { memo, useMemo, } from "react";



const ProductColorFormController = function () {


    const fieldSchema = useMemo(
        () => [
            {
                title: "Name",
                placeholder: "Enter name",
                key: "name",
                type: "text",
                required: true,
                default: "",
            },
            {
                title: "Color",
                placeholder: "Enter color",
                key: "color",
                type: "color",
                required: true,
                default: "",
            },
        ],
        []
    );
    return <FormController

        routeForRedirect="/productcolor/"
        title=" Product Color"
        inputFiedsSchema={fieldSchema}
        addFun={addProductColorApi}
        getByIdFun={() => { }}
        updateFun={() => { }}

    />
};
export default memo(ProductColorFormController);
