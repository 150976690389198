export default {
  email_required: "Email is required",
  email_already_exist: "Email is already exist",
  email_invalid: "Email is invalid",
  user_not_found: "User is not registered",
  password_required: "Password is required",
  password_wrong: "Password is wrong",
  validation_error: "Validation error",
  validation_error_default: "Please fill all field with right format",
  server_error: "Server Error",
  server_error_description: "Something went wrong at server!",
  not_found_error: "Not Found Error",
  not_found_error_description: "Content you search seems like not exist.",
  unknown_error: "Unknown Error",
  something_wrong: "Something went wrong!",
  restriction_error: "Restriction error",
  restriction_error_description: "Content you search is retricted to specific person",
  bad_gateway_error: "Bad gateway",
  bad_gateway_error_description:
    "Server is not available to accept the request please try after sometime!",
  session_expire_error: "session is expired",
  session_expire_error_description: "Due to security reason your session is no more aviable.",

  address_required: "Address required",
  area_required: "Area required",
  bank_account_required: "Bank account required",
  bank_ifsc_required: "Bank ifsc required",
  bank_name_required: "Bank name required",
  category_not_found: "Category not found",
  category_required: "Category required",
  city_required: "City required",
  close_time_required: "Close time required",
  discount_amount_required: "Discount amount required",

  field_required: "Field required",
  "Maximum payload is allowed upto 80mb": "Maximum payload is allowed upto 80mb",
  files_required: "Images required",
  files_invalid: "Images are not valid or not uploded.",
  file_url_required: "File url required",
  forbidden_error: "Forbidden error",
  gender_invalid: "Gender invalid",
  gender_required: "Gender required",
  id_rerquired: "Id rerquired",
  latitude_required: "Latitude required",
  longitude_required: "Longitude required",
  name_required: "Name required",
  content_is_not_avaiable: "Content is not avaiable",
  no_file_uploaded: "No file uploaded",
  offer_not_exist: "Offer not exist",
  offer_required: "Offer required",
  offer_code_already_exist: "Offer code already exist",
  offer_code_required: "Offer code required",
  open_time_required: "Open time required",
  origin_required: "Origin required",
  page_not_found: "Page not found",

  phone_no_already_exist: "Phone no already registered",
  phone_no_required: "Phone no required",
  phone_no_invalid: "Phone no is invalid",
  pincode_required: "Pincode required",
  price_required: "Price required",
  priority_required: "Priority required",
  sallon_not_found: "Sallon not found",
  sallon_required: "Sallon required",

  slug_already_exist: "Slug already exist",
  slug_required: "Slug required",
  status_invalid: "Status invalid",
  status_required: "Status required",
  token_expired: "Token expired",
  token_malformed: "Token malformed",
  token_not_found: "Token not found",
  token_not_valid: "Token not valid",
  user_id_required: "User id required",

  value_required: "Value required",
  extension_is_not_allowed: "Extension is not allowed",

  'username_exist': 'username already exist',
  'username_invalid': 'username is invalid',
  'username_required': 'username is required',
  no_admin: "You are not an admin"
};
