const changeInputAction = (key, value) => {
  const actionPrams = {
    type: "CHANGE_INPUT",
    key,
    value,
  };
  return actionPrams;
};
export const changeWholeState = ( value) => {
  const actionPrams = {
    type: "CHANGE_WHOLE_STATE",    
    value,
  };
  return actionPrams;
};
export default changeInputAction;
