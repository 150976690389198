import axios from "axios";
import { getHeaders } from "utils/helper";
import endpoints from "./endpoints";

export const deleteFile = async (data) => {
  const callResponse = await axios({
    url: endpoints.fileBase,
    method: "DELETE",
    data,
    headers: getHeaders(),
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};
export default {
  deleteFile
}