import { memo } from "react";
import PropTypes from "prop-types";
import { Card, Grid, Typography } from "@mui/material";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDTypography from "components/MDTypography";
import FormInput from "components/custom/FormInput";
import MDButton from "components/MDButton";

const FormUi = function ({
  onSubmit,
  fieldSchema,
  loading,
  dispatch,
  title,
  fields,
  isUpdate,
  fetchUpdateDataLoading,
}) {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Card sx={{ padding: 5 }}>
          <MDTypography sx={{ mb: 3 }} variant='h3'>
            {isUpdate ? "UPDATE" : "ADD"} {title.toUpperCase()}
          </MDTypography>

          {!fetchUpdateDataLoading && (
            <MDBox component='form' role='form' onSubmit={onSubmit}>

              <Grid container spacing={2}>
                {fieldSchema.map(field => {
                  if (
                    (isUpdate && field.not_editable) ||
                    (!isUpdate && field.not_creatable) ||
                    field.hidden
                  ) {
                    return null;
                  }

                  return (
                    <FormInput
                      value={fields[field.key]}
                      defaultImages={field.type === "file" ? fields.images : []}
                      origin={title}
                      loading={loading}
                      key={field.key}
                      field={field}
                      labelKey={field.labelKey ? field.labelKey : "name"}
                      valueKey={field.valueKey ? field.valueKey : "_id"}
                      dispatch={dispatch}
                    />
                  );
                })}
              </Grid>
              <MDButton
                type='submit'
                sx={{ width: "100%", marginTop: 3 }}
                variant='contained'
                color='info'
              >
                {loading ? "Loading" : "Submit"}
              </MDButton>
            </MDBox>
          )}
          {fetchUpdateDataLoading && (
            <MDBox>
              <Typography align='center' variant='h4'>
                Loading...
              </Typography>
            </MDBox>
          )}
        </Card>
      </MDBox>
    </DashboardLayout>
  );
};
export default memo(FormUi);
FormUi.propTypes = {
  title: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  fields: PropTypes.objectOf(PropTypes.any).isRequired,
  fieldSchema: PropTypes.arrayOf(PropTypes.any).isRequired,
  onSubmit: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  isUpdate: PropTypes.bool.isRequired,
  fetchUpdateDataLoading: PropTypes.bool.isRequired,
};
