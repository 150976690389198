import PropTypes from "prop-types";
import { Icon, IconButton } from "@mui/material";

const EditButton = function ({ onUpdateButtonClick }) {
  return (
    <IconButton onClick={onUpdateButtonClick} aria-label='delete' color='info' size='small'>
      <Icon fontSize='inherit'>edit</Icon>
    </IconButton>
  );
};
export default EditButton;

EditButton.propTypes = {
  onUpdateButtonClick: PropTypes.func.isRequired,
};
