import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";
import PropTypes from "prop-types";
import {

    Chip,
    Icon,
    IconButton,
    TableRow,

} from "@mui/material";


import EditButton from "components/custom/EditButton";

import useModal from "hooks/useModal";
import { memo } from "react";
import EditInputComponent from "pages/layout/EditInputComponent";
import DeleteButton from "components/custom/DeleteButton";


const PermissionRow = function ({ data, currentParams, columns, onDelete, onUpdateButtonClick, onBooleanChanges }) {

    const { openModal } = useModal();

    const updateModal = (schema, val) => {





        openModal(
            <EditInputComponent value={val} schema={schema} data={data} onUpdate={onBooleanChanges} />
        );
    }

    const updateAllowed = () => {

        const schema = columns[1]

        const val = null
        const roles = data.allowed && Array.isArray(data.allowed) && data.allowed.map((allowed) =>
            allowed.rolecode

        )


        openModal(
            <EditInputComponent value={val} schema={schema} data={data} onUpdate={async (field, value, id) => {

                if (value && Array.isArray(value)) {
                    value.forEach(item => {
                        if (roles.indexOf(item) === -1) {
                            roles.push(item);

                        }
                    })

                }

                await onBooleanChanges(field, roles, id)
            }} />
        );
    }

    const onRemovePermission = async (code) => {

        const roles = data.allowed && Array.isArray(data.allowed) && data.allowed.map((allowed) =>
            allowed.rolecode

        )

        const index = roles.indexOf(parseInt(code, 10));
        if (index > -1) {
            roles.splice(index, 1);
        }
        /* eslint no-underscore-dangle: 0 */
        await onBooleanChanges('allowed', roles, data._id)

    }

    return (
        <TableRow>
            <DataTableBodyCell  >
                {data.title} <EditButton
                    onUpdateButtonClick={() => {
                        /* eslint no-underscore-dangle: 0 */
                        updateModal(columns[0], data.title)

                    }}
                />
            </DataTableBodyCell>

            <DataTableBodyCell  >
                {data.allowed && Array.isArray(data.allowed) && data.allowed.map((allowed) =>
                    <Chip label={allowed.title} onDelete={() => { onRemovePermission(allowed.rolecode) }} />

                )}


                <IconButton onClick={updateAllowed} aria-label='delete' color='info' size='small'>
                    <Icon fontSize='inherit'>add</Icon>
                </IconButton>


            </DataTableBodyCell>
           
            <DataTableBodyCell>
                <DeleteButton
                    onDelete={() => {

                        onDelete(data._id);
                    }}
                /></DataTableBodyCell>

        </TableRow>
    )
}
PermissionRow.propTypes = {
    data: PropTypes.objectOf.isRequired,
    currentParams: PropTypes.objectOf.isRequired,
    onDelete: PropTypes.func.isRequired,
    onUpdateButtonClick: PropTypes.func.isRequired,
    onBooleanChanges: PropTypes.func.isRequired,
    columns: PropTypes.arrayOf(PropTypes.any).isRequired
};
export default memo(PermissionRow);
