/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 React layouts
import Dashboard from "layouts/dashboard";
import Tables from "layouts/tables";
import Billing from "layouts/billing";

import Profile from "layouts/profile";

// @mui icons
import Icon from "@mui/material/Icon";
import RolesFormController from "pages/roles/RolesFormController";
import RolesListingController from "pages/roles/RolesListingController";
import LeadTypeListingController from "pages/leadtype/LeadTypeListingController";
import LeadTypeFormController from "pages/leadtype/LeadTypeFormController";
import LeadStatusListingController from "pages/leadstatus/LeadStatusListingController";
import LeadStatusFormController from "pages/leadstatus/LeadStatusFormController";
import InventoryCategoryListingController from "pages/inventoryCategory/InventoryCategoryListingController";
import InventoryCategoryFormController from "pages/inventoryCategory/InventoryCategoryFormController";
import ProductColorListingController from "pages/productColor/ProductColorListingController";
import ProductColorFormController from "pages/productColor/ProductColorFormController";
import ProductTypeListingController from "pages/productType/ProductTypeListingController";
import ProductTypeFormController from "pages/productType/ProductTypeFormController";
import UsersListingController from "pages/user/UsersListingController";
import UsersFormController from "pages/user/UsersFormController";
import PermissionListingController from "pages/permissions/PermissionListingController";
import PermissionFormController from "pages/permissions/PermissionFormController";
import ProductListingController from "pages/product/ProductListingController";
import ProductFormController from "pages/product/ProductFormController";
import RepairingStatusFormController from "pages/repairingStatus/RepairingStatusFormController";
import RepairingStatusListingController from "pages/repairingStatus/RepairingStatusListingController";
import RepairingProblemsListingController from "pages/repairingProblems/RepairingProblemsListingController";
import RepairingProblemsFormController from "pages/repairingProblems/RepairingProblemsFormController";
import RepairingSourceListingController from "pages/source/RepairingSourceListingController";
import RepairingSourceFormController from "pages/source/RepairingSourceFormController";
import ProductCategroyListingController from "pages/productCatgeory/ProductCategroyListingController";
import ProductCategroyFormController from "pages/productCatgeory/ProductCategroyFormController";




// import OfferController from "pages/offer/ListController";
// import OfferAddController from "pages/offer/FormController";

// import SallonController from "pages/sallon/ListController";
// import SallonAddController from "pages/sallon/FormController";


// import SerivcesController from "pages/services/ListController";
// import SerivcesAddController from "pages/services/FormController";


// import SallonOfferController from "pages/salloonoffer/ListController";
// import SallonOfferAddController from "pages/salloonoffer/FormController";

// import EnquiryController from "pages/enquiry/ListController";

const routes = [


  {
    type: "collapse",
    title: "Users",
    collapse: [
      {
        type: "collapse",
        name: "user ",
        key: "user",
        icon: <Icon fontSize='small'>list</Icon>,
        route: "/user",
        component: <UsersListingController />,
      },
      {
        type: "collapse",
        name: "Add user",
        
        key: "user/add",
        icon: <Icon fontSize='small'>add</Icon>,
        route: "/user/add",
        component: <UsersFormController />,
      }
    ],
    // route:'/category',
    // component: <CategoryController />,
  },

  {
    type: "collapse",
    title: "permissions",
    collapse: [
      {
        type: "collapse",
        name: "permission ",
        key: "permission",
        icon: <Icon fontSize='small'>list</Icon>,
        route: "/permission",
        component: <PermissionListingController />,
      },
      {
        type: "collapse",
        name: "Add permission",
        
        key: "permission/add",
        icon: <Icon fontSize='small'>add</Icon>,
        route: "/permission/add",
        component: <PermissionFormController />,
      }
    ],
    // route:'/category',
    // component: <CategoryController />,
  },

  {
    type: "collapse",
    title: "Roles",
    collapse: [
      {
        type: "collapse",
        name: "Roles ",
        key: "roles",
        icon: <Icon fontSize='small'>list</Icon>,
        route: "/roles",
        component: <RolesListingController />,
      },
      {
        type: "collapse",
        name: "Add Roles",
        
        key: "roles/add",
        icon: <Icon fontSize='small'>add</Icon>,
        route: "/roles/add",
        component: <RolesFormController />,
      }
    ],
    // route:'/category',
    // component: <CategoryController />,
  },


  {
    type: "collapse",
    title: "Lead Type",
    collapse: [
      {
        type: "collapse",
        name: "Lead Type ",
        key: "leadtype",
        icon: <Icon fontSize='small'>list</Icon>,
        route: "/leadtype",
        component: <LeadTypeListingController />,
      },
      {
        type: "collapse",
        name: "Add Lead Type",
        
        key: "leadtype/add",
        icon: <Icon fontSize='small'>add</Icon>,
        route: "/leadtype/add",
        component: <LeadTypeFormController />,
      }
    ],
    // route:'/category',
    // component: <CategoryController />,
  },



  {
    type: "collapse",
    title: "Lead Status",
    collapse: [
      {
        type: "collapse",
        name: "Lead Status ",
        key: "leadstatus",
        icon: <Icon fontSize='small'>list</Icon>,
        route: "/leadstatus",
        component: <LeadStatusListingController />,
      },
      {
        type: "collapse",
        name: "Add Lead Status",
        
        key: "leadstatus/add",
        icon: <Icon fontSize='small'>add</Icon>,
        route: "/leadstatus/add",
        component: <LeadStatusFormController />,
      }
    ],
    // route:'/category',
    // component: <CategoryController />,
  },


  {
    type: "collapse",
    title: "Inventory Category",
    collapse: [
      {
        type: "collapse",
        name: "Inventory Category ",
        key: "inventorycategory",
        icon: <Icon fontSize='small'>list</Icon>,
        route: "/inventorycategory",
        component: <InventoryCategoryListingController />,
      },
      {
        type: "collapse",
        name: "Add Inventory Category",
        
        key: "inventorycategory/add",
        icon: <Icon fontSize='small'>add</Icon>,
        route: "/inventorycategory/add",
        component: <InventoryCategoryFormController />,
      }
    ],
    // route:'/category',
    // component: <CategoryController />,
  },


  {
    type: "collapse",
    title: "Product",
    collapse: [
      {
        type: "collapse",
        name: "Product  ",
        key: "product",
        icon: <Icon fontSize='small'>list</Icon>,
        route: "/product",
        component: <ProductListingController />,
      },
      {
        type: "collapse",
        name: "Add Product ",
        
        key: "product/add",
        icon: <Icon fontSize='small'>add</Icon>,
        route: "/product/add",
        component: <ProductFormController />,
      }
    ],
    // route:'/category',
    // component: <CategoryController />,
  },


  
  {
    type: "collapse",
    title: "Product Category",
    collapse: [
      {
        type: "collapse",
        name: "Product category ",
        key: "product-category",
        icon: <Icon fontSize='small'>list</Icon>,
        route: "/product-category",
        component: <ProductCategroyListingController />,
      },
      {
        type: "collapse",
        name: "Add Product Category ",
        
        key: "product-category/add",
        icon: <Icon fontSize='small'>add</Icon>,
        route: "/product-category/add",
        component: <ProductCategroyFormController />,
      }
    ],
    // route:'/category',
    // component: <CategoryController />,
  },

  {
    type: "collapse",
    title: "Product Colors",
    collapse: [
      {
        type: "collapse",
        name: "Product Colors ",
        key: "productcolor",
        icon: <Icon fontSize='small'>list</Icon>,
        route: "/productcolor",
        component: <ProductColorListingController />,
      },
      {
        type: "collapse",
        name: "Add Product Colors",
        
        key: "productcolor/add",
        icon: <Icon fontSize='small'>add</Icon>,
        route: "/productcolor/add",
        component: <ProductColorFormController />,
      }
    ],
    // route:'/category',
    // component: <CategoryController />,
  },


  {
    type: "collapse",
    title: "Product Type",
    collapse: [
      {
        type: "collapse",
        name: "Product Type ",
        key: "producttype",
        icon: <Icon fontSize='small'>list</Icon>,
        route: "/producttype",
        component: <ProductTypeListingController />,
      },
      {
        type: "collapse",
        name: "Add Product Type",
        
        key: "producttype/add",
        icon: <Icon fontSize='small'>add</Icon>,
        route: "/producttype/add",
        component: <ProductTypeFormController />,
      }
    ],
    // route:'/category',
    // component: <CategoryController />,
  },

  {
    type: "collapse",
    title: "Repairing Statuses",
    collapse: [
      {
        type: "collapse",
        name: "Repairing Statuses",
        key: "repairing-status",
        icon: <Icon fontSize='small'>list</Icon>,
        route: "/repairing-status",
        component: <RepairingStatusListingController />,
      },
      {
        type: "collapse",
        name: "Add Repairing Statuses",
        
        key: "repairing-status/add",
        icon: <Icon fontSize='small'>add</Icon>,
        route: "/repairing-status/add",
        component: <RepairingStatusFormController />,
      }
    ],
    // route:'/category',
    // component: <CategoryController />,
  },


  {
    type: "collapse",
    title: "Repairing Problems",
    collapse: [
      {
        type: "collapse",
        name: "Repairing Problems",
        key: "repairing-problems",
        icon: <Icon fontSize='small'>list</Icon>,
        route: "/repairing-problems",
        component: <RepairingProblemsListingController />,
      },
      {
        type: "collapse",
        name: "Add Repairing Problems",
        
        key: "repairing-problems/add",
        icon: <Icon fontSize='small'>add</Icon>,
        route: "/repairing-problems/add",
        component: <RepairingProblemsFormController />,
      }
    ],
    // route:'/category',
    // component: <CategoryController />,
  },

  {
    type: "collapse",
    title: "Repairing Source",
    collapse: [
      {
        type: "collapse",
        name: "Repairing Source",
        key: "repairing-source",
        icon: <Icon fontSize='small'>list</Icon>,
        route: "/repairing-source",
        component: <RepairingSourceListingController />,
      },
      {
        type: "collapse",
        name: "Add Repairing Source",
        
        key: "repairing-source/add",
        icon: <Icon fontSize='small'>add</Icon>,
        route: "/repairing-source/add",
        component: <RepairingSourceFormController />,
      }
    ],
    // route:'/category',
    // component: <CategoryController />,
  },
  // {
  //   type: "collapse",
  //   title: "offer",
  //   // title: "Category",
  //   // noCollapse:1,
  //   collapse: [
  //     {
  //       type: "collapse",
  //       name: "List Offer",
  //       key: "offer",
  //       icon: <Icon fontSize='small'>list</Icon>,
  //       route: "/offer",
  //       component: <OfferController />,
  //     },
  //     {
  //       type: "collapse",
  //       name: "Add Offer",
  //       key: "offer/add",
  //       icon: <Icon fontSize='small'>add</Icon>,
  //       route: "/offer/add",
  //       component: <OfferAddController />,
  //     },
  //     {
  //       type: "hidden",
        
  //       key: "offer/update",        
  //       route: "/offer/update/:id",
  //       component: <OfferAddController />,
  //     },
  //   ],
  //   // route:'/category',
  //   // component: <CategoryController />,
  // },

  
  {
    type: "divider",
  },
  {
    type: "title",
    title: "Layouts",
  },
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize='small'>dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },

  {
    type: "collapse",
    name: "Tables",
    key: "tables",
    icon: <Icon fontSize='small'>table_view</Icon>,
    route: "/tables",
    component: <Tables />,
  },
  {
    type: "collapse",
    name: "Billing",
    key: "billing",
    icon: <Icon fontSize='small'>receipt_long</Icon>,
    route: "/billing",
    component: <Billing />,
  },

  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    icon: <Icon fontSize='small'>person</Icon>,
    route: "/profile",
    component: <Profile />,
  },
 
];

export default routes;
