
import { getRolesApi ,deleteRolesApi,updateRolesField} from "apis/roles.api";
import ListController from "pages/layout/ListController";
import { memo, useMemo, } from "react";

const RoleListingController = function () {

   
    const columSchema = useMemo(
        () => [
            {
                id: "title",
                name: "title",
                align: "left",
                accessPath: ['title'],
                editable: true,
                inputType: "text",
                placeholder: "Enter title",
                key: "title",
                required: true,
                isSorted: true
            },

            {
                id: "rolecode",
                name: "rolecode",
                align: "left",
                accessPath: ['rolecode'],
                editable: true,
                inputType: "number",
                placeholder: "Enter Rolecode",
                key: "rolecode",
                required: true,
                isSorted: true
            },

            {
                id: "Action",
                name: "Action",
                align: "left",
                isBlank:true,
                isSorted: false
            },
        ],
        []
    );
    return <ListController

        deleteFun={deleteRolesApi}
        getFun={getRolesApi}
        onFieldChange={updateRolesField}
        columnSchema={columSchema}
        baseRoute="/roles/"
        title="User Roles"
    />
};
export default memo(RoleListingController);
