import * as React from "react";
import Button from "@mui/material/Button";

import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import PropTypes, { array, number, object, string } from "prop-types";
import useModal from "hooks/useModal";
import MDButton from "components/MDButton";
import { useState } from "react";
import useAlert from "hooks/useAlert";
import lang from "utils/lang";
import FormInput from "components/custom/FormInput";

function EditComponent({ value, data, schema, onUpdate }) {
  const { closeModal } = useModal();
  
  const alert = useAlert();
  const [val, setVal] = useState(value)

  const [loading, setLoading] = useState(false);

  const updateFun = async () => {
    try {
      setLoading(true);
      /* eslint no-underscore-dangle: 0 */
      await onUpdate(schema.key, val, data._id);
      closeModal()
      setLoading(false);
    } catch (e) {
      alert.showAlert(lang("unknown_error"), lang("something_wrong"), "");
      setLoading(false);
    }
  };
  return (
    <>
      <DialogTitle>{schema.placeholder}</DialogTitle>
      <DialogContent style={{overflowY:"unset"}}>

        <FormInput

            fullWidth='true'

            value={val}
            // defaultImages={field.type === "file" ? fields.images : []}
            defaultImages={[]}
            origin=''
            // use it while file
            loading={loading}
            key={schema.key}
            field={schema}
            labelKey={schema.labelKey ? schema.labelKey : "name"}
            valueKey={schema.valueKey ? schema.valueKey : "_id"}
            dispatch={(changedVal) => {
              setVal(changedVal.value)
            }}
          />



      </DialogContent>
      <DialogActions>
        <MDButton disabled={loading} variant='text' color='info' onClick={closeModal}>
          CANCLE
        </MDButton>
        <MDButton disabled={loading} variant='contained' onClick={updateFun} color='info'>
          UPDATE
        </MDButton>
      </DialogActions>
    </>
  );
}
export default EditComponent;
EditComponent.propTypes = {
  value: PropTypes.oneOfType([string, number, undefined, object, array, null]).isRequired,
  onUpdate: PropTypes.func.isRequired,
  schema: PropTypes.objectOf(PropTypes.any).isRequired,
  data: PropTypes.objectOf(PropTypes.any).isRequired
};
