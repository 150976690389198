import { useCallback, useState } from "react";

const useControlledField = defaultField => {
  const [state, setState] = useState(defaultField);

  const handleField = useCallback(
    (method = "", key, value = "") => {
      const statement = "";
      if (method === "reset") setState(defaultField);
      else if (Array.isArray(key) && method === "set") {
        const obj = {};
        key.forEach((field, index) => {
          obj[field] = value[index];
        });
        setState({ ...state, ...obj });
      } else if (method === "set") {
        const current = { ...state };
        current[key] = value;
        setState(current);
      }
      return statement;
    },
    [setState, state]
  );

  // const getField =

  return [state, handleField];
};
export default useControlledField;
