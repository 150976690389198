import { memo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { CircularProgress, Switch } from "@mui/material";
import Creatable from "react-select/creatable";
import { withAsyncPaginate } from "react-select-async-paginate";
import useApiCall from "hooks/useApiCall";

const DropDown = function(props){
  return <Creatable {...props}  />
}
const CreatableAsyncPaginate = withAsyncPaginate(DropDown);

const DynamicDropDown = function ({ lazyFun, onSelect, defaultOption, labelKey, valueKey,multi }) {
  
  const [value, onChange] = useState(null);

  const [loading, setLoading] = useState(false);
  const [params, setParams] = useState({
    pageSize: 10,
    pageNo: 1,
    hasMore: true,
    forDropDown: true,
  });
  const apicallFun = useApiCall();
  const loadOptions = async (search, prevOptions) => {
    if (!loading) {
      const currentPraams = { ...params };
      let hasMore = false;
      setLoading(true);
      const response = await apicallFun(
        async () => {
          const callResponse = await lazyFun({
            ...currentPraams,
            search,
            pageNo: Math.ceil(prevOptions.length / params.pageSize) + 1,
          });

          return callResponse;
        },
        async data => {
          if (data.pageNo * data.pageSize <= data.total) hasMore = true;
          setLoading(false);
          return data.result;
        },
        err => {
          setLoading(false);
          return err;
        }
      );

      const finalArray = [];
      response.forEach(row => {
        
        /* eslint no-underscore-dangle: 0 */
        finalArray.push({
          label: row[labelKey],
          value: row[valueKey],
        });
      });
      return {
        options: finalArray,
        hasMore,
      };
    }

    return {
      options: prevOptions,
      hasMore: true,
    };
  };
  return (
    <CreatableAsyncPaginate
      value={value}
      isMulti={multi}
      defaultOptions={defaultOption}
      loadOptions={loadOptions}
      defaultAdditional
      onChange={val => {
        onChange(val);        
        onSelect(val);
      }}
    />
  );
};
DynamicDropDown.defaultProps = {
  defaultOption: [],
  labelKey: "name",
  valueKey: "_id",
  multi:false
}
DynamicDropDown.propTypes = {
  multi:PropTypes.bool,
  labelKey: PropTypes.string,
  valueKey: PropTypes.string,
  lazyFun: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  defaultOption: PropTypes.arrayOf(PropTypes.any)
};
export default memo(DynamicDropDown);
