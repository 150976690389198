
import { updateProductColorField, getProductColorApi, deleteProductColorApi } from "apis/product.api";

import ListController from "pages/layout/ListController";
import { memo, useMemo, } from "react";

const ProductColorListingController = function () {


    const columSchema = useMemo(
        () => [
            {
                id: "name",
                name: "name",
                align: "left",
                accessPath: ['name'],
                editable: true,
                inputType: "text",
                placeholder: "Enter name",
                key: "name",
                required: true,
                isSorted: true
            },

            {
                id: "color",
                name: "color",
                align: "left",
                accessPath: ['color'],
                editable: true,
                inputType: "color",
                placeholder: "Enter color",
                key: "color",
                required: true,
                isSorted: true
            },

            {
                id: "Action",
                name: "Action",
                align: "left",
                isBlank: true,
                isSorted: false
            },
        ],
        []
    );
    return <ListController

        deleteFun={deleteProductColorApi}
        getFun={getProductColorApi}
        onFieldChange={updateProductColorField}
        columnSchema={columSchema}
        baseRoute="/productcolor/"
        title="Product Color"
    />
};
export default memo(ProductColorListingController);
