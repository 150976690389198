import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";
import { memo, useState } from "react";
import PropTypes from "prop-types";
import {

  TableRow,

} from "@mui/material";
import { getGender } from "utils/helper";
import DeleteButton from "components/custom/DeleteButton";
import EditButton from "components/custom/EditButton";

import useModal from "hooks/useModal";
import EditInputComponent from "./EditInputComponent";

const Row = function ({ data, currentParams, columns, onDelete, onUpdateButtonClick, onBooleanChanges }) {

  const { openModal } = useModal();

  const updateModal = (schema) => {

    let val = data

    schema.accessPath.forEach((path) => { if (val) { val = val[path] } })



    openModal(
      <EditInputComponent value={val} schema={schema} data={data} onUpdate={onBooleanChanges} />
    );
  }

  return (
    <TableRow>

      {columns && columns.map((schema) => {

        if (schema.isBlank)
          return null

        let val = data

        schema.accessPath.forEach((path) => { if (val) { val = val[path] } })

        return <DataTableBodyCell key={schema.id} >
          {schema.inputType === 'checkbox' ? <> {val && val === true ? 'yes' : "no"}</> : (val)} 

        {schema.editable &&  <EditButton
            onUpdateButtonClick={() => {

              /* eslint no-underscore-dangle: 0 */
              updateModal(schema)

            }}
          />}
        </DataTableBodyCell>
      })}
      <DataTableBodyCell>
        <DeleteButton
          onDelete={() => {
            /* eslint no-underscore-dangle: 0 */
            onDelete(data._id);
          }}
        /></DataTableBodyCell>

    </TableRow>
  )
}
Row.propTypes = {
  data: PropTypes.objectOf.isRequired,
  currentParams: PropTypes.objectOf.isRequired,
  onDelete: PropTypes.func.isRequired,
  onUpdateButtonClick: PropTypes.func.isRequired,
  onBooleanChanges: PropTypes.func.isRequired,
  columns: PropTypes.arrayOf(PropTypes.any).isRequired
};
export default memo(Row);
