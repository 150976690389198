
import { addTicketProblemsApi } from "apis/repairing.api";
import FormController from "pages/layout/FormController";
import { memo, useMemo, } from "react";



const RepairingProblemsFormController = function () {


    const fieldSchema = useMemo(
        () => [
            {
                title: "Name",
                placeholder: "Enter name",
                key: "name",
                type: "text",
                required: true,
                default: "",
            },
            {
                title: "Priority",
                placeholder: "Enter priority",
                key: "priority",
                type: "number",
                required: true,
                default: "",
            },
            {
                title: "Is inputField",
                placeholder: "Is inputField",
                key: "inputField",
                type: "checkbox",
                default:false,
                
            },
        ],
        []
    );
    return <FormController

        routeForRedirect="/repairing-problems/"
        title=" Repairing problems"
        inputFiedsSchema={fieldSchema}
        addFun={addTicketProblemsApi}
        getByIdFun={() => { }}
        updateFun={() => { }}

    />
};
export default memo(RepairingProblemsFormController);
