import PropTypes from "prop-types";
import { Icon, IconButton } from "@mui/material";
import useModal from "hooks/useModal";
import DeleteAlert from "./DeleteAlert";

const DeleteButton = function ({ onDelete }) {
  const { openModal } = useModal();
  const deleteConfirmation = () => openModal(<DeleteAlert onDelete={onDelete} />);

  return (
    <IconButton onClick={deleteConfirmation} aria-label='delete' color='primary' size='small'>
      <Icon fontSize='inherit'>delete</Icon>
    </IconButton>
  );
};
export default DeleteButton;

DeleteButton.propTypes = {
  onDelete: PropTypes.func.isRequired,
};
