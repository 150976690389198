import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";
import PropTypes from "prop-types";
import {

    Chip,
    Icon,
    IconButton,
    TableRow,

} from "@mui/material";


import EditButton from "components/custom/EditButton";

import useModal from "hooks/useModal";
import { memo } from "react";
import EditInputComponent from "pages/layout/EditInputComponent";
import DeleteButton from "components/custom/DeleteButton";


const ProductRow = function ({ data, currentParams, columns, onDelete, onUpdateButtonClick, onBooleanChanges }) {

    const { openModal } = useModal();

    const updateModal = (schema, val) => {

        openModal(
            <EditInputComponent value={val} schema={schema} data={data} onUpdate={onBooleanChanges} />
        );
    }

    const updateSubProduct = () => {

        const schema = columns[4]

        const val = null
        const subProducts = data.subProduct && Array.isArray(data.subProduct) && data.subProduct.map((subProduct) =>
            subProduct._id

        )


        openModal(
            <EditInputComponent value={val} schema={schema} data={data} onUpdate={async (field, value, id) => {

                if (value && Array.isArray(value)) {
                    value.forEach(item => {
                        if (subProducts.indexOf(item) === -1) {
                            subProducts.push(item);

                        }
                    })

                }

                await onBooleanChanges(field, subProducts, id)
            }} />
        );
    }

    const onRemoveSubProduct = async (id) => {

        const subProducts = data.subProduct && Array.isArray(data.subProduct) && data.subProduct.map((subProduct) =>
            subProduct._id

        )

        const index = subProducts.indexOf(id);
        if (index > -1) {
            subProducts.splice(index, 1);
        }
        /* eslint no-underscore-dangle: 0 */
        await onBooleanChanges('subProduct', subProducts, data._id)

    }

    return (
        <TableRow>
            <DataTableBodyCell  >
                {data.name} <EditButton
                    onUpdateButtonClick={() => {
                        /* eslint no-underscore-dangle: 0 */
                        updateModal(columns[0], data.name)

                    }}
                />
            </DataTableBodyCell>


            <DataTableBodyCell  >
                {data.mrp} <EditButton
                    onUpdateButtonClick={() => {
                        /* eslint no-underscore-dangle: 0 */
                        updateModal(columns[1], data.mrp)

                    }}
                />
            </DataTableBodyCell>


            <DataTableBodyCell  >
                {data.type && data.type.name} <EditButton
                    onUpdateButtonClick={() => {
                        /* eslint no-underscore-dangle: 0 */
                        updateModal(columns[2], data.type && data.type.name)

                    }}
                />
            </DataTableBodyCell>


            <DataTableBodyCell  >
                {data.color && data.color.name} <EditButton
                    onUpdateButtonClick={() => {
                        /* eslint no-underscore-dangle: 0 */
                        updateModal(columns[3], data.color && data.color.name)

                    }}
                />
            </DataTableBodyCell>

            <DataTableBodyCell  >
                {data.subProduct && Array.isArray(data.subProduct) && data.subProduct.map((subProduct) =>
                    <Chip label={subProduct.name} onDelete={() => { onRemoveSubProduct(subProduct._id) }} />

                )}


                <IconButton onClick={updateSubProduct} aria-label='delete' color='info' size='small'>
                    <Icon fontSize='inherit'>add</Icon>
                </IconButton>


            </DataTableBodyCell>

            <DataTableBodyCell  >
                {data.productCategory && data.productCategory.name} <EditButton
                    onUpdateButtonClick={() => {
                        /* eslint no-underscore-dangle: 0 */
                        updateModal(columns[5], data.productCategory && data.productCategory.name)

                    }}
                />
            </DataTableBodyCell>
           
            <DataTableBodyCell>
                <DeleteButton
                    onDelete={() => {
                        onDelete(data._id);
                    }}
                /></DataTableBodyCell>

        </TableRow>
    )
}
ProductRow.propTypes = {
    data: PropTypes.objectOf(PropTypes.any).isRequired,
    currentParams: PropTypes.objectOf(PropTypes.any).isRequired,
    onDelete: PropTypes.func.isRequired,
    onUpdateButtonClick: PropTypes.func.isRequired,
    onBooleanChanges: PropTypes.func.isRequired,
    columns: PropTypes.arrayOf(PropTypes.any).isRequired
};
export default memo(ProductRow);
