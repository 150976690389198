import PropTypes from "prop-types";

import useApiCall from "hooks/useApiCall";
import useValidatior from "hooks/useValidatior";
import { useLayoutEffect, useCallback, memo, useMemo, useState, useReducer } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { changeWholeState } from "store/action/changeInputAction";
import inputFieldReducer from "store/reducer/inputFieldReducer";

import FormUi from "./FormUi";

const FormController = function ({ updateFun, getByIdFun, addFun, inputFiedsSchema, title, routeForRedirect }) {
  const { validate } = useValidatior();
  const params = useParams();
  const navigate = useNavigate();
  const apiCallFun = useApiCall();
  const [loading, setLoading] = useState(false);
  const [fetchUpdateDataLoading, setUpdateDataLoading] = useState(false);

  const fieldSchema = useMemo(
    () => inputFiedsSchema,
    []
  );
  const intialParams = useMemo(() => {
    if (params.id) {
      return {};
    }
    const defaultParams = {};
    fieldSchema.forEach(field => {
      if (field.key) {
        defaultParams[field.key] = field.default;
      }
    });
    return defaultParams;
  }, []);

  const [fields, dispatch] = useReducer(inputFieldReducer, intialParams);

  const init = async any => {
    try {
      setUpdateDataLoading(true);
      const paramsFromId = {};
      await apiCallFun(
        async () => {
          const response = await getByIdFun({ id: params.id });
          return response;
        },
        async data => {
          fieldSchema.forEach(field => {
            if (field.key && !field.path) {
              paramsFromId[field.key] =
                data[field.key] || data[field.key] === null || data[field.key] === 0
                  ? data[field.key]
                  : field.default;
            }
            if (field.path && Array.isArray(field.path)) {
              let val = data;
              field.path.forEach(innerpath => {
                val = val[innerpath];
              });
              paramsFromId[field.key] = val || val === null || val === 0 ? val : field.default;
            }
          });
          if (data.images && Array.isArray(data.images)) {
            paramsFromId.images = data.images;
          }
          setUpdateDataLoading(false);

          return 1;
        },
        async error => {
          setUpdateDataLoading(false);
          navigate(routeForRedirect);
          return 0;
        }
      );

      dispatch(changeWholeState(paramsFromId));
      return 1;
    } catch (e) {
      return 0;
    }
  };
  const onSubmit = useCallback(
    async e => {
      e.preventDefault();
      if (validate(fields, fieldSchema, params.id)) {
        const formData = new FormData();

        fieldSchema.forEach(field => {
          if (field.type === "file") {
            if (fields[field.key] && Array.isArray(fields[field.key])) {
              fields[field.key].forEach(file => {
                formData.append(field.key, file);
              });
            } else {
              formData.append(field.key, fields[field.key]);
            }
          } else {
            formData.append(field.key, fields[field.key]);
          }
        });

        setLoading(true);
        await apiCallFun(
          async () => {
            if (params.id) {
              formData.append("id", params.id);
              const response = await updateFun(formData);
              return response;
            }
            const response = await addFun(formData);
            return response;
          },
          async data => {
            setLoading(false);
            navigate(routeForRedirect);
          },
          async error => {
            setLoading(false);
          }
        );
      }
    },
    [fields, fieldSchema]
  );

  useLayoutEffect(() => {
    if (params.id) {
      init();
    }
  }, [params.id]);

  return (
    <FormUi
      fetchUpdateDataLoading={fetchUpdateDataLoading}
      title={title}
      isUpdate={params.id}
      loading={loading}
      fields={fields}
      dispatch={dispatch}
      onSubmit={onSubmit}
      fieldSchema={fieldSchema}
    />
  );
};
export default memo(FormController);


FormController.propTypes = {

  updateFun: PropTypes.func.isRequired,
  getByIdFun: PropTypes.func.isRequired,
  addFun: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  routeForRedirect: PropTypes.string.isRequired,
  inputFiedsSchema: PropTypes.arrayOf(PropTypes.any).isRequired
};
