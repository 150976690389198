import { Card, CardMedia, Grid, Input } from "@mui/material";
import { deleteFile } from "apis/common.api";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import PropTypes from "prop-types";

import { useState, memo, useEffect } from "react";
import { useParams } from "react-router-dom";

const FileViewer = function ({ id, defaultFiles, single, onDelete, onChangeFile, origin }) {
  const [files, setFiles] = useState(defaultFiles ?? []);
  const [objectUrls, setObjectUrls] = useState(defaultFiles ?? []);

  useEffect(() => {
    const existingFiles = [...files];
    const existingObjectUrls = [...objectUrls];
    if (defaultFiles && Array.isArray(defaultFiles)) {
      defaultFiles.forEach(fileurl => {
        existingFiles.push(fileurl);
        existingObjectUrls.push(fileurl);
      });
    }
    setFiles(existingFiles);
    setObjectUrls(existingObjectUrls);
  }, [defaultFiles]);
  const params = useParams();

  const onDeleteButtonClick = async index => {
    const temp = [...files];
    const urls = [...objectUrls];
    const url = urls[index];
    temp.splice(index, 1);
    urls.splice(index, 1);

    if (onDelete && url.slice(0, 4) !== "blob") {
      await deleteFile({ file_url: url, origin, id: params.id });
      onDelete(url.slice(9));
    }

    setFiles(temp);
    setObjectUrls(urls);
    onChangeFile(temp.filter(fileUrl => fileUrl.slice(0, 4) !== "blob"));
  };

  const onChange = e => {
    const temp = [...files];
    const urls = [...objectUrls];
    if (single) {
      if (onDelete && defaultFiles && defaultFiles.length > 0) {
        onDelete(defaultFiles[0].slice(9));
      }

      Array.from(e.target.files).forEach(file => {
        temp[0] = file;
        urls[0] = URL.createObjectURL(file);
      });
    } else {
      Array.from(e.target.files).forEach(file => {
        temp.push(file);
        urls.push(URL.createObjectURL(file));
      });
    }

    setFiles(temp);
    
    onChangeFile(temp.filter(fileUrl => fileUrl.slice(0, 4) !== "blob"));
    setObjectUrls(urls);
    document.getElementById(id ?? "files").value = "";
  };

  return (
    <MDBox>
      <MDBox
        sx={{
          border: "1px dashed gray",
          height: "100px",
          position: "relative",
          alignItems: "center",
          justifyContent: "center",
        }}
        display='flex'
      >
        <MDTypography color='black' variant='h5'>
          Upload files
        </MDTypography>
        <input
          style={{
            opacity: 0,
            position: "absolute",
            top: "0px",
            left: "0px",
            width: "100%",
            height: "100%",

            zIndex: 1,
          }}
          accept='image/*'
          type='file'
          onChange={onChange}
          multiple={!single}
          name='files'
          id={id ?? "files"}
        />
      </MDBox>
      <Grid container spacing={2} mt={1}>
        {objectUrls.map((url, index) => (
          <Grid xs={3} item key={url}>
            <Card
              sx={{
                display: "flex",
                flexDirection: "column",
                backgroundColor: "transparent",
                boxShadow: "none",
                overflow: "visible",
              }}
            >
              <MDBox position='relative' width='100.25%' shadow='xl' borderRadius='xl'>
                <CardMedia
                  src={url}
                  component='img'
                  title=''
                  sx={{
                    maxWidth: "100%",
                    margin: 0,
                    boxShadow: ({ boxShadows: { md } }) => md,
                    objectFit: "cover",
                    objectPosition: "center",
                  }}
                />
              </MDBox>
              <MDBox mt={1} mx={0.5}>
                <MDButton
                  onClick={() => {
                    onDeleteButtonClick(index);
                  }}
                  sx={{ width: "100%" }}
                  rel='noreferrer'
                  color='error'
                  variant='contained'
                >
                  Remove
                </MDButton>
              </MDBox>
            </Card>
          </Grid>
        ))}
      </Grid>
    </MDBox>
  );
};
export default memo(FileViewer);

FileViewer.propTypes = {
  id: PropTypes.string.isRequired,
  single: PropTypes.bool.isRequired,
  defaultFiles: PropTypes.arrayOf(PropTypes.any).isRequired,
  onDelete: PropTypes.func.isRequired,
  onChangeFile: PropTypes.func.isRequired,
  origin: PropTypes.string.isRequired,
};
