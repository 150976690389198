import { avialableGender } from "./constants";

export const accessToken = {
  set: val => {
    localStorage.setItem("wehear_access_token", val);
  },
  get: () => localStorage.getItem("wehear_access_token"),
  remove: () => {
    localStorage.removeItem("wehear_access_token");
  },
};
export const refreshToken = {
  set: val => {
    localStorage.setItem("wehear_refresh_token", val);
  },
  get: () => localStorage.getItem("wehear_refresh_token"),
  remove: () => {
    localStorage.removeItem("wehear_refresh_token");
  },
};
export const logOut = () => {
  refreshToken.remove();
  accessToken.remove();
};
/* eslint-disable */
export const isEmail = val => /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(val.trim());

export const getHeaders = () => {
  const token = `Bearer ${accessToken.get()}`;
  return {
    Authorization: token,
  };
};
export function setZeroPrefix (val) {
  if (parseInt(val, 10) < 10) {
    return `0${val}`;
  }
  return val;
}

export const dateConverter = ({ type, value }) => {
  if (type === "DD_MM_YYYY") {
    const dateObj = new Date(value);
    return `${setZeroPrefix(dateObj.getDate())}/${setZeroPrefix(
      dateObj.getMonth() + 1
    )}/${dateObj.getFullYear()}`;
  }
  return value;
};
export const getGender = (index)=>{
  const gender = Object.values(avialableGender).indexOf(index)
  return  Object.keys(avialableGender)[gender]
}
export const  validateEmail=(email)=> {
  var re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
  return re.test(email);
}
export const  validatePhone=(phone)=> {
  var re = /^\d{10}$/;
  return phone.match(re);
}