import axios from "axios";
import { getHeaders } from "utils/helper";
import endpoints from "./endpoints";

export const getModulePermissionsApi = async params => {
    const callResponse = await axios({
        url: endpoints.modulePermissions,
        method: "get",
        headers: getHeaders(),
        params,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};

export const deleteModulePermissionsApi = async data => {
    const callResponse = await axios({
        url: endpoints.modulePermissions,
        method: "DELETE",
        headers: getHeaders(),
        data,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};
export const addModulePermissionsApi = async data => {

    const callResponse = await axios({
        url: endpoints.modulePermissions,
        method: "POST",
        headers: { 'Content-Type': 'application/json', ...getHeaders() },
        data: JSON.stringify(
            {
                allowed: data.get('allowed').split(','),
                title: data.get('title'),
                forFrontEnd: data.get('forFrontEnd')
            }
        ),
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};

export const updateModulePermissionsField = async data => {
    const callResponse = await axios({
        url: endpoints.modulePermissions,
        method: "PUT",
        headers: { 'Content-Type': 'application/json', ...getHeaders() },
        data,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};
export default updateModulePermissionsField