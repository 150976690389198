
import { updateLeadTypeField,getLeadTypeApi,deleteLeadTypeApi} from "apis/lead.api";

import ListController from "pages/layout/ListController";
import { memo, useMemo, } from "react";

const LeadTypeListingController = function () {


    const columSchema = useMemo(
        () => [
            {
                id: "name",
                name: "name",
                align: "left",
                accessPath: ['name'],
                editable: true,
                inputType: "text",
                placeholder: "Enter name",
                key: "name",
                required: true,
                isSorted: true
            },

            {
                id: "priority",
                name: "priority",
                align: "left",
                accessPath: ['priority'],
                editable: true,
                inputType: "number",
                placeholder: "Enter priority",
                key: "priority",
                required: true,
                isSorted: true
            },

            {
                id: "Action",
                name: "Action",
                align: "left",
                isBlank: true,
                isSorted: false
            },
        ],
        []
    );
    return <ListController

        deleteFun={deleteLeadTypeApi}
        getFun={getLeadTypeApi}
        onFieldChange={updateLeadTypeField}
        columnSchema={columSchema}
        baseRoute="/leadtype/"
        title="Lead Type"
    />
};
export default memo(LeadTypeListingController);
