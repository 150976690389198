import axios from "axios";
import { getHeaders } from "utils/helper";
import endpoints from "./endpoints";

export const getRolesApi = async params => {
  const callResponse = await axios({
    url: endpoints.userRoleBase,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};

export const deleteRolesApi = async data => {
  const callResponse = await axios({
    url: endpoints.userRoleBase,
    method: "DELETE",
    headers: getHeaders(),
    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};
export const addRolesApi = async data => {
  const callResponse = await axios({
    url: endpoints.userRoleBase,
    method: "POST",
    headers:getHeaders(),
    // headers: { "Content-Type": "multipart/form-data", ...getHeaders() },
    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};

export const updateRolesField = async data => {
  const callResponse = await axios({
    url: endpoints.userRoleBase,
    method: "PUT",
    headers: getHeaders(),
    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};
export default {
    getRolesApi,
    updateRolesField
};
