/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import PropTypes from "prop-types";
import { useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import useAlert from "hooks/useAlert";
import { CircularProgress } from "@mui/material";

function SignInUi ({ loading, onSubmit, handleState, state }) {
  const alert = useAlert();

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant='gradient'
          bgColor='info'
          borderRadius='lg'
          coloredShadow='info'
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign='center'
        >
          <MDTypography variant='h4' fontWeight='medium' color='white' mt={1}>
            Sign in
          </MDTypography>
        </MDBox>

        <MDBox pt={4} pb={3} px={3}>
          <MDBox component='form' role='form' onSubmit={onSubmit}>
            <MDBox mb={2}>
              <MDInput
                disabled={loading}
                value={state.email}
                onChange={e => {
                  handleState("set", "email", e.target.value);
                }}
                type='text'
                label='Email'
                fullWidth
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                disabled={loading}
                type='password'
                value={state.password}
                onChange={e => {
                  handleState("set", "password", e.target.value);
                }}
                label='Password'
                fullWidth
              />
            </MDBox>

            <MDBox mt={4} mb={1}>
              <MDButton variant='gradient' disabled={loading} type='submit' color='info' fullWidth>
                {!loading ? "sign in" : <CircularProgress size='10' />}
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default SignInUi;
SignInUi.propTypes = {
  loading: PropTypes.bool.isRequired,
  handleState: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  state: PropTypes.objectOf(PropTypes.any).isRequired,
};
