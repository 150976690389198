const mode = "production";
let domain = "";

switch (mode) {
  case "local":
    domain = "http://localhost:8000/";
    break;
  case "development":
    domain = "https://wehear-crm-development.up.railway.app/";
    break;
  case "production":
    domain = "https://wehear-crm-production.up.railway.app/";
    break;
  default:
    domain = "http://localhost:8000/";
}

export default {
  signIn: `${domain}api/auth/sign-in`,
  resetToken: `${domain}api/auth/reset-token`,
  userAndSystemFetchByToken: `${domain}api/system/`,
  userBase: `${domain}api/user/`,
  userRoleBase: `${domain}api/userrole/`,

  products: `${domain}api/product/`,
  productCategory: `${domain}api/product/product-category`,
  productColors: `${domain}api/product/color/`,
  inventoryCategorys: `${domain}api/product/category/`,
  productTypes: `${domain}api/product/type/`,

  leadTypes: `${domain}api/lead/type/`,
  leadStatuss: `${domain}api/lead/status/`,
  modulePermissions: `${domain}api/module-permissions/`,
  ticketStatus: `${domain}api/repair/status/`,
  ticketProblems: `${domain}api/repair/problems/`,
  sourceOfSales: `${domain}api/source/`,

  // categoryBase: `${domain}api/category/`,
  // categoryById: `${domain}api/category/id/`,

  // offerBase: `${domain}api/offer/`,
  // offerById: `${domain}api/offer/id/`,

  // sallonBase: `${domain}api/sallon/`,
  // sallonById: `${domain}api/sallon/id/`,

  // servicesBase: `${domain}api/service/`,
  // servicesById: `${domain}api/service/id/`,

  // sallonOfferBase: `${domain}api/sallon-offer/`,
  // sallonOfferById: `${domain}api/sallon-offer/id/`,

  // enquiryBase: `${domain}api/enquiry/`,

  fileBase: `${domain}api/file/`,

};
