import * as React from "react";
import Dialog from "@mui/material/Dialog";
import PropTypes from "prop-types";
import {  Zoom } from "@mui/material";

const Transition = React.forwardRef((props, ref) => <Zoom direction='down'  ref={ref} {...props} />);

function PopUpModal ({ open, component, closeModal }) {

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      sx={{overflowY:"visible"}}
      onClose={closeModal}
      aria-describedby='alert-dialog-slide-description'
    >
      <div>
      {component && component}
      </div>
      {/*         
        <DialogTitle>{"Use Google's location service?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Let Google help apps determine location. This means sending anonymous
            location data to Google, even when no apps are running.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          <Button onClick={handleClose}>Agree</Button>
        </DialogActions> */}
    </Dialog>
  );
}
export default PopUpModal;

PopUpModal.propTypes = {
  open: PropTypes.bool.isRequired,
  component: PropTypes.node.isRequired,
  closeModal: PropTypes.func.isRequired,
};
