import MDSnackbar from "components/MDSnackbar";
import React, { createContext, useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";

export const alertType = {
  ALERT: "alert",
  WARNING: "warning",
  SUCCESS: "success",
  INFO: "info",
};

export const AlertContext = createContext({
  type: alertType.ALERT,
  showAlert: (title = "", content = "", dateTime = "") => { },
  showWarning: (title = "", content = "", dateTime = "") => { },
  showInfo: (title = "", content = "", dateTime = "") => { },
  showSuccess: (title = "", content = "", dateTime = "") => { },
});

function AlertContextPovider({ children }) {
  const [alert, setAlert] = useState({
    type: alertType.ALERT,
    show: false,
    icon: "Star",
    title: "Alert!",
    content: "OOPS! Something went wrong!",
    dateTime: "just now",
  });

  const showAlert = useCallback((title = "", content = "", dateTime = "") => {
    setAlert({
      ...alert.content,
      type: alertType.ALERT,
      show: true,
      icon: "warning",
      title,
      content,
      dateTime,
    });
  }, []);
  const showWarning = useCallback((title = "", content = "", dateTime = "") => {
    setAlert({
      ...alert.content,
      type: alertType.WARNING,
      show: true,
      icon: "star",
      title,
      content,
      dateTime,
    });
  }, []);
  const showSuccess = useCallback((title = "", content = "", dateTime = "") => {
    setAlert({
      ...alert.content,
      type: alertType.SUCCESS,
      show: true,
      icon: "check",
      title,
      content,
      dateTime,
    });
  }, []);
  const showInfo = useCallback((title = "", content = "", dateTime = "") => {
    setAlert({
      ...alert.content,
      type: alertType.INFO,
      show: true,
      icon: "notifications",
      title,
      content,
      dateTime,
    });
  }, []);

  let timeout;
  useEffect(() => {
    if (alert.show) {
      if (timeout) {
        clearTimeout(timeout)
      }

      setTimeout(()=>{
        setAlert({ ...alert, show: false });
      },5000)
    }
    return () => clearTimeout(timeout)
  }, [alert])
  return (
    <AlertContext.Provider
      value={useMemo(() => {

        const passData = { ...alert, showAlert, showWarning, showInfo, showSuccess }
        return passData
      }, [alert])}
    >
      <MDSnackbar
        key={alert.title}
        color={alert.type}
        icon={alert.icon}
        title={alert.title}
        content={alert.content}
        dateTime={alert.dateTime}
        open={alert.show}
        onClose={() => {
          setAlert({ ...alert, show: false });
        }}
        close={() => {
          setAlert({ ...alert, show: false });
        }}
        bgWhite
      />
      {children}
    </AlertContext.Provider>
  );
}
export default AlertContextPovider;

AlertContextPovider.propTypes = {
  children: PropTypes.node.isRequired,
};
