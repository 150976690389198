import { useCallback, useContext } from "react";
import { AlertContext } from "store/AlertContextPovider";
import { isEmail } from "utils/helper";
import lang from "utils/lang";
import useAlert from "./useAlert";

const useValidatior = () => {
  const alert = useAlert();

  const validate = useCallback(
    (state, schema, isUpdate = false) => {
      let validated = true;

      if (schema && Array.isArray(schema)) {
        for (let i = 0; i < schema.length; i += 1) {
          const field = schema[i];

          if (!(isUpdate && field.not_editable) || !(!isUpdate && field.not_creatable)) {
            if (!field.multiple && field.type !== "dropdown" && field.type !== "checkbox") {
              if (
                field.required === true &&
                ((!state[field.key] && state[field.key] !== 0) ||
                  state[field.key].toString().trim() === "")
              ) {
                validated = false;
                alert.showAlert(lang(`validation_error`), lang(`${field.key}_required`), "");
                break;
              }

              if (field.type === "email" && state[field.key] && !isEmail(state[field.key])) {
                validated = false;
                alert.showAlert(lang(`validation_error`), lang(`${field.key}_invalid`), "");
                break;
              }
            } else if (
              field.required === true &&
              field.multiple &&
              field.type !== "dropdown" &&
              field.type !== "checkbox" &&
              field.type !== "file" &&
              ((!state[field.key] && state[field.key] !== 0) ||
                !Array.isArray(state[field.key]) ||
                state[field.key].length <= 0)
            ) {
              validated = false;
              alert.showAlert(lang(`validation_error`), lang(`${field.key}_required`), "");
              break;
            } else if (field.type === "dropdown") {
              if (
                field.required === true &&
                ((!state[field.key] && state[field.key] !== 0) ||
                  state[field.key].toString().trim() === "")
              ) {
                validated = false;
                alert.showAlert(lang(`validation_error`), lang(`${field.key}_required`), "");
                break;
              }
              if (
                field.enum &&
                Array.isArray(field.enum) &&
                state[field.key] &&
                !field.enum.includes(state[field.key])
              ) {
                validated = false;
                alert.showAlert(lang(`validation_error`), lang(`${field.key}_invalid`), "");
                break;
              }
            }
          }
        }
      }
      return validated;
    },
    [alert]
  );

  return { validate };
};

export default useValidatior;
