
import { addProductApi,getProductColorApi,getProductTypeApi ,getProductApi,getProductCategory} from "apis/product.api";


import FormController from "pages/layout/FormController";
import { memo, useMemo, } from "react";



const RolesFormController = function () {


    const fieldSchema = useMemo(
        () => [
            {
                title: "Name",
                placeholder: "Enter Name",
                key: "name",
                type: "text",
                required: true,
                default: "",
            },
            {
                title: "MRP",
                placeholder: "Enter MRP",
                key: "mrp",
                type: "number",
                required: true,
                default: "",
            },
           
            {
                title: "type",
                placeholder: "Select type",
                key: "type",
                type: "dropdown",
                lazyFun: getProductTypeApi,
                labelKey:"name",
                valueKey:"_id",
                required: true,
                default: "",
            },
            {
                title: "color",
                placeholder: "Select color",
                key: "color",
                type: "dropdown",
                lazyFun: getProductColorApi,
                labelKey:"name",
                valueKey:"_id",
                required: true,
                default: "",
            },
            {
                title: "Sub Product",
                placeholder: "select sub products",
                key: "subProduct",
                type: "dropdown",
                lazyFun: getProductApi,
                labelKey:"name",
                multi:true,
                valueKey:"_id",
                
                default: "",
            },
            {
                title: "Product Category",
                placeholder: "select product category",
                key: "productCategory",
                type: "dropdown",
                lazyFun: getProductCategory,
                labelKey:"name",                
                valueKey:"_id",
                required: false,
                default: "",
            },
           
        ],
        []
    );
    return <FormController

        routeForRedirect="/product/"
        title="product"
        inputFiedsSchema={fieldSchema}
        addFun={addProductApi}
        getByIdFun={() => { }}
        updateFun={() => { }}

    />
};
export default memo(RolesFormController);
