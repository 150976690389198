import axios from "axios";
import { getHeaders } from "utils/helper";
import endpoints from "./endpoints";

export const getProductApi = async params => {
    const callResponse = await axios({
        url: endpoints.products,
        method: "get",
        headers: getHeaders(),
        params,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};

export const deleteProductApi = async data => {
    const callResponse = await axios({
        url: endpoints.products,
        method: "DELETE",
        headers: getHeaders(),
        data,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};
export const addProductApi = async data => {
    const callResponse = await axios({
        url: endpoints.products,
        method: "POST",
        headers: { 'Content-Type': 'application/json', ...getHeaders() },
        // headers: { "Content-Type": "multipart/form-data", ...getHeaders() },
        data,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};

export const updateProductField = async data => {
    const callResponse = await axios({
        url: endpoints.products,
        method: "PUT",
        headers: { 'Content-Type': 'application/json', ...getHeaders() },
        data,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};



export const getProductCategory = async params => {
    const callResponse = await axios({
        url: endpoints.productCategory,
        method: "get",
        headers: getHeaders(),
        params,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};

export const deleteProductCategory = async data => {
    const callResponse = await axios({
        url: endpoints.productCategory,
        method: "DELETE",
        headers: getHeaders(),
        data,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};
export const addProductCategory = async data => {
    const callResponse = await axios({
        url: endpoints.productCategory,
        method: "POST",
        headers: { 'Content-Type': 'application/json', ...getHeaders() },
        // headers: { "Content-Type": "multipart/form-data", ...getHeaders() },
        data,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};

export const updateProductCategoryField = async data => {
    const callResponse = await axios({
        url: endpoints.productCategory,
        method: "PUT",
        headers: { 'Content-Type': 'application/json', ...getHeaders() },
        data,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};



export const getProductColorApi = async params => {
    const callResponse = await axios({
        url: endpoints.productColors,
        method: "get",
        headers: getHeaders(),
        params,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};

export const deleteProductColorApi = async data => {
    const callResponse = await axios({
        url: endpoints.productColors,
        method: "DELETE",
        headers: getHeaders(),
        data,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};
export const addProductColorApi = async data => {
    const callResponse = await axios({
        url: endpoints.productColors,
        method: "POST",
        headers: getHeaders(),
        // headers: { "Content-Type": "multipart/form-data", ...getHeaders() },
        data,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};

export const updateProductColorField = async data => {
    const callResponse = await axios({
        url: endpoints.productColors,
        method: "PUT",
        headers: getHeaders(),
        data,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};


export const getProductTypeApi = async params => {
    const callResponse = await axios({
        url: endpoints.productTypes,
        method: "get",
        headers: getHeaders(),
        params,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};

export const deleteProductTypeApi = async data => {
    const callResponse = await axios({
        url: endpoints.productTypes,
        method: "DELETE",
        headers: getHeaders(),
        data,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};
export const addProductTypeApi = async data => {
    const callResponse = await axios({
        url: endpoints.productTypes,
        method: "POST",
        headers: getHeaders(),
        // headers: { "Content-Type": "multipart/form-data", ...getHeaders() },
        data,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};

export const updateProductTypeField = async data => {
    const callResponse = await axios({
        url: endpoints.productTypes,
        method: "PUT",
        headers: getHeaders(),
        data,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};




export const getInventoryCategoryApi = async params => {
    const callResponse = await axios({
        url: endpoints.inventoryCategorys,
        method: "get",
        headers: getHeaders(),
        params,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};

export const deleteInventoryCategoryApi = async data => {
    const callResponse = await axios({
        url: endpoints.inventoryCategorys,
        method: "DELETE",
        headers: getHeaders(),
        data,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};
export const addInventoryCategoryApi = async data => {
    const callResponse = await axios({
        url: endpoints.inventoryCategorys,
        method: "POST",
        headers: getHeaders(),
        // headers: { "Content-Type": "multipart/form-data", ...getHeaders() },
        data,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};

export const updateInventoryCategoryField = async data => {
    const callResponse = await axios({
        url: endpoints.inventoryCategorys,
        method: "PUT",
        headers: getHeaders(),
        data,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};




export default {
    getProductColorApi,
    updateProductColorField
};
