import axios from "axios";
import { getHeaders } from "utils/helper";
import endpoints from "./endpoints";

export const getLeadStatusApi = async params => {
    const callResponse = await axios({
        url: endpoints.leadStatuss,
        method: "get",
        headers: getHeaders(),
        params,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};

export const deleteLeadStatusApi = async data => {
    const callResponse = await axios({
        url: endpoints.leadStatuss,
        method: "DELETE",
        headers: getHeaders(),
        data,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};
export const addLeadStatusApi = async data => {
    const callResponse = await axios({
        url: endpoints.leadStatuss,
        method: "POST",
        headers: getHeaders(),
        // headers: { "Content-Type": "multipart/form-data", ...getHeaders() },
        data,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};

export const updateLeadStatusField = async data => {
    const callResponse = await axios({
        url: endpoints.leadStatuss,
        method: "PUT",
        headers: getHeaders(),
        data,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};


export const getLeadTypeApi = async params => {
    const callResponse = await axios({
        url: endpoints.leadTypes,
        method: "get",
        headers: getHeaders(),
        params,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};

export const deleteLeadTypeApi = async data => {
    const callResponse = await axios({
        url: endpoints.leadTypes,
        method: "DELETE",
        headers: getHeaders(),
        data,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};
export const addLeadTypeApi = async data => {
    const callResponse = await axios({
        url: endpoints.leadTypes,
        method: "POST",
        headers: getHeaders(),
        // headers: { "Content-Type": "multipart/form-data", ...getHeaders() },
        data,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};

export const updateLeadTypeField = async data => {
    const callResponse = await axios({
        url: endpoints.leadTypes,
        method: "PUT",
        headers: getHeaders(),
        data,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};


export default {
    getLeadStatusApi,
    updateLeadStatusField
};
