
import { getUserAndSystemDataApi } from "apis/user.api";
import useApiCall from "hooks/useApiCall";
import PropTypes from "prop-types";
import { createContext, useCallback, useEffect, useMemo, useState } from "react";
import { accessToken, logOut, refreshToken } from "../utils/helper";

export const UserContext = createContext({ isLoggedIn: false, data: {} });

function UserContextPovider ({ children }) {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState({ isLoggedIn: false, data: {} });
  const apiCallFun = useApiCall();
  const fetchUser = useCallback(async () => {
    if (accessToken.get()) {
      setLoading(true);
      // const response = await ;
      await apiCallFun(
        getUserAndSystemDataApi,
        (data) => {
          
          setUser({ isLoggedIn: true, data });
          setLoading(false);
        },
        () => {
          setUser({ isLoggedIn: false, data: {} });
          setLoading(false);
        }
      );
      
    }
    setLoading(false);
  }, [setUser]);

  const login = useCallback(data => {
    accessToken.set(data.accessToken);
    refreshToken.set(data.refreshToken);
    setUser({...user, isLoggedIn: true, data });
  }, [setUser]);

  const logout = useCallback(() => {
    logOut();
    setUser({...user, isLoggedIn: false, data: {} });
    
  }, [setUser,logOut]);

  useEffect(() => {
    fetchUser();
  }, []);

  const value = useMemo(() => {
    const passData = { ...user, loading, login, logout };
    return passData;
  }, [user, loading]);

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}
export default UserContextPovider;

UserContextPovider.propTypes = {
  children: PropTypes.node.isRequired,
};
