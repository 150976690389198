
import { addLeadTypeApi } from "apis/lead.api";
import FormController from "pages/layout/FormController";
import { memo, useMemo, } from "react";



const LeadTypeFormController = function () {


    const fieldSchema = useMemo(
        () => [
            {
                title: "Name",
                placeholder: "Enter name",
                key: "name",
                type: "text",
                required: true,
                default: "",
            },
            {
                title: "Priority",
                placeholder: "Enter priority",
                key: "priority",
                type: "number",
                required: true,
                default: "",
            },
        ],
        []
    );
    return <FormController

        routeForRedirect="/leadtype/"
        title="lead Type"
        inputFiedsSchema={fieldSchema}
        addFun={addLeadTypeApi}
        getByIdFun={() => { }}
        updateFun={() => { }}

    />
};
export default memo(LeadTypeFormController);
