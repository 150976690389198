import PopUpModal from "components/custom/PopUpModal";
import React, { createContext, useCallback, useMemo, useState } from "react";
import PropTypes from "prop-types";

export const CustomModalContext = createContext({
  show: false,
});

function CustomModalProvider ({ children }) {
  const [modal, setModal] = useState({
    show: false,
    component: null,
  });

  const openModal = useCallback(
    component => {
      setModal({ ...modal, show: true, component });
    },
    [setModal]
  );
  const closeModal = useCallback(() => {
    setModal({ ...modal, show: false, component: null });
  }, [setModal]);

  return (
    <CustomModalContext.Provider
      value={useMemo(() => {
        const passData = { modal, openModal, closeModal };
        return passData;
      }, [modal])}
    >
      <PopUpModal open={modal.show} component={modal.component} closeModal={closeModal} />
      {children}
    </CustomModalContext.Provider>
  );
}
export default CustomModalProvider;

CustomModalProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
