import * as React from "react";
import { memo } from "react";

import PropTypes from "prop-types";
import { Autocomplete, Grid, Switch, Zoom } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import changeInputAction from "store/action/changeInputAction";
import FileViewer from "./FileViewer";
import DynamicDropDown from "./DynamicDropDown";

function FormInput({ field, dispatch, onDelete, loading, origin, value, defaultImages, fullWidth, labelKey, valueKey }) {
  // this is for listingController purpose
  /* eslint-disable no-param-reassign */
  if (field.inputType)
    field.type = field.inputType

  return (
    <Grid item sm={fullWidth ? 12 : 6} xs={12} key={field.key}>
      {field.type !== "file" &&
        field.type !== "radio" &&
        field.type !== "checkbox" &&
        field.type !== "dropdown" && (
          <MDBox>
            <MDTypography variant='h6'>{field.title}</MDTypography>
            <MDInput
              value={value}
              onChange={e => {
                dispatch(changeInputAction(field.key, e.target.value));
              }}
              sx={{ width: "100%" }}
              placeholder={field.placeholder}
              disabled={loading}
              type={field.type}
            />
          </MDBox>
        )}
      {field.type === "checkbox" && (
        <MDBox>
          <MDTypography variant='h6'>{field.title}</MDTypography>
          <Switch
            value={value}
            onChange={e => {
              dispatch(changeInputAction(field.key, e.target.checked));
            }}
          />
        </MDBox>
      )}
      {(field.type === "radio" || field.type === "dropdown") && (
        <MDBox>
          <MDTypography variant='h6'>{field.title}</MDTypography>
          {field.lazyFun ? (

            <DynamicDropDown
              multi={field.multi ? field.multi : false}
              labelKey={labelKey}
              valueKey={valueKey}
              value={value}
              lazyFun={field.lazyFun}
              onSelect={val => {
                if (field.multi) {
                  if (val && Array.isArray(val)) {
                    const arr = val.map(valObj => valObj.value)
                    dispatch(changeInputAction(field.key, arr));
                  } else
                    dispatch(changeInputAction(field.key, null));

                } else {
                  dispatch(changeInputAction(field.key, val.value));
                }

              }}

            />

          ) : (
            <Autocomplete
              // getOptionLabel={option => option.label}
              sx={{ width: "100%", p: 0 }}
              value={field.option.reduce((val, option) => {
                if (option.value === value) return option;
                return val;
              }, null)}
              options={field.option}
              onChange={(event, newValue) => {
                dispatch(changeInputAction(field.key, newValue.value));
              }}
              disabled={loading}
              getOptionLabel={option => option.label}
              renderInput={params => (
                <MDInput
                  placeholder={field.placeholder}
                  disabled={loading}
                  sx={{ padding: 0 }}
                  {...params}
                />
              )}
            />
          )}
        </MDBox>
      )}

      {field.type === "file" && (
        <MDBox>
          <MDTypography variant='h6'>{field.title}</MDTypography>
          <FileViewer
            origin={origin}
            disabled={loading}
            id='files'
            defaultFiles={defaultImages ?? []}
            single={false}
            onDelete={() => { }}
            onChangeFile={files => {
              dispatch(changeInputAction(field.key, files));
            }}
          />
        </MDBox>
      )}
    </Grid>
  );
}
export default memo(FormInput);
FormInput.defaultProps = {
  fullWidth: false,
  labelKey: "name",
  valueKey: "_id"
}
FormInput.propTypes = {
  labelKey: PropTypes.string,
  valueKey: PropTypes.string,
  fullWidth: PropTypes.bool,
  field: PropTypes.objectOf(PropTypes.any).isRequired,
  dispatch: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  origin: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.objectOf(PropTypes.any),
    PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  ]).isRequired,
  defaultImages: PropTypes.arrayOf(PropTypes.string).isRequired,
};
