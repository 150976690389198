import { Autocomplete, Icon, Table, TableBody, TableContainer, TableRow } from "@mui/material";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDPagination from "components/MDPagination";
import MDTypography from "components/MDTypography";
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";
import useControlledField from "hooks/useControlledField";
import PropTypes from "prop-types";
import { useMemo, useCallback, memo, useEffect } from "react";

const Datatable = function ({
  rows,
  total,
  initialParams,
  columns,
  renderRow,
  onChangeParams,
  loading,
}) {
  const [currentParams, handleParams] = useControlledField(initialParams);

  const pageSizes = useMemo(() => ["10", "20", "50", "100"], []);
  const setSortedValue = useCallback(
    column => {
      const { sort } = { ...currentParams };

      if (column.isSorted) {
        if (sort && sort.slice(0, -5) === column.id && sort.slice(-4) === "asce") {
          return "asce";
        }
        if (sort && sort.slice(0, -5) === column.id && sort.slice(-4) === "desc") {
          return "desc";
        }
        return "none";
      }
      return false;
    },
    [columns, currentParams]
  );
  useEffect(() => {
    onChangeParams(currentParams);
  }, [currentParams]);
  const onSortButtonClick = useCallback(
    column => () => {
      const sort = setSortedValue(column);
      if (sort === "asce") {
        handleParams("set", "sort", `${column.id}_desc`);
      }
      if (sort === "desc" || sort === "none") {
        handleParams("set", "sort", `${column.id}_asce`);
      }
    },
    [columns, handleParams]
  );

  const previousPage = useCallback(() => {
    handleParams("set", "pageNo", currentParams.pageNo - 1);
  }, [handleParams, initialParams]);
  const nextPage = useCallback(() => {
    handleParams("set", "pageNo", currentParams.pageNo + 1);
  }, [handleParams, initialParams]);

  return (
    <TableContainer sx={{ boxShadow: "none" }}>
      {currentParams.pageSize || currentParams.search ? (
        <MDBox display='flex' justifyContent='space-between' alignItems='center' p={3}>
          {currentParams.pageSize && (
            <MDBox display='flex' alignItems='center'>
              <Autocomplete
                disableClearable
                value={currentParams.pageSize.toString()}
                options={pageSizes}
                onChange={(event, newValue) => {
                  handleParams("set", "pageSize", parseInt(newValue, 10));
                }}
                size='small'
                sx={{ width: "5rem" }}
                renderInput={params => <MDInput {...params} />}
              />
              <MDTypography variant='caption' color='secondary'>
                &nbsp;&nbsp;entries per page
              </MDTypography>
            </MDBox>
          )}

          {(currentParams.search || currentParams.search !== false) && (
            <MDBox width='12rem' ml='auto'>
              <MDInput
                placeholder='Search...'
                value={currentParams.search}
                size='small'
                fullWidth
                onChange={({ currentTarget }) => {
                  handleParams("set", "search", currentTarget.value);
                }}
              />
            </MDBox>
          )}
        </MDBox>
      ) : null}
      <Table>
        <MDBox component='thead'>
          <TableRow>
            {columns.map(column => (
              <DataTableHeadCell
                key={column.id}
                width={column.width ? column.width : "auto"}
                align={column.align ? column.align : "left"}
                sorted={setSortedValue(column)}
                onClick={onSortButtonClick(column)}
              >
                {column.name}
              </DataTableHeadCell>
            ))}
          </TableRow>
        </MDBox>
        <TableBody>
          {!loading &&
            rows.map((rowdata, key) =>
              /* eslint no-underscore-dangle: 0 */
              renderRow(rowdata, key, currentParams)
            )}

          {loading && (
            <TableRow>
              <DataTableBodyCell colspan={columns.length}>
                <MDTypography
                  variant='h2'
                  sx={{ width: "100%", textAlign: "center" }}
                  color='secondary'
                >
                  Loading...
                </MDTypography>
              </DataTableBodyCell>
            </TableRow>
          )}
        </TableBody>
      </Table>

      {currentParams.pageNo && (
        
        <MDBox
          display='flex'
          flexDirection={{ xs: "column", sm: "row" }}
          justifyContent='space-between'
          alignItems={{ xs: "flex-start", sm: "center" }}
          p={3}
        >          
          <MDBox mb={{ xs: 3, sm: 0 }}>
            <MDTypography variant='button' color='secondary' fontWeight='regular'>
              Showing {currentParams.pageSize * (currentParams.pageNo - 1) + 1} to{" "}
              {currentParams.pageSize * (currentParams.pageNo - 1) + rows.length} of {total} entries
            </MDTypography>
          </MDBox>

          <MDPagination variant='gradient' color='info'>
            {currentParams.pageNo !== 1 && (
              <MDPagination item onClick={() => previousPage()}>
                <Icon sx={{ fontWeight: "bold" }}>chevron_left</Icon>
              </MDPagination>
            )}

            {currentParams.pageNo * currentParams.pageSize < total && (
              <MDPagination item onClick={() => nextPage()}>
                <Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>
              </MDPagination>
            )}
          </MDPagination>
        </MDBox>
      )}
      
    </TableContainer>
  );
};

export default memo(Datatable);

Datatable.defaultProps = {
  initialParams: { pageSize: 10, pageNo: 1, search: null, sort: null },
  total: 0,
  loading: false,
  rows: [],
  columns: [],
};
Datatable.propTypes = {
  initialParams: PropTypes.shape({
    pageSize: PropTypes.number,
    pageNo: PropTypes.number,
    search: PropTypes.oneOfType([PropTypes.string, false]),
    sort: PropTypes.string,
  }),
  loading: PropTypes.bool,
  total: PropTypes.number,
  rows: PropTypes.arrayOf(PropTypes.object),
  columns: PropTypes.arrayOf(PropTypes.object),
  renderRow: PropTypes.func.isRequired,
  onChangeParams: PropTypes.func.isRequired,
};

// Typechecking props for the DataTable
// DataTable.propTypes = {
//   entriesPerPage: PropTypes.oneOfType([
//     PropTypes.shape({
//       defaultValue: PropTypes.number,
//       entries: PropTypes.arrayOf(PropTypes.number),
//     }),
//     PropTypes.bool,
//   ]),
//   canSearch: PropTypes.bool,
//   showTotalEntries: PropTypes.bool,
//   table: PropTypes.objectOf(PropTypes.array).isRequired,
//   pagination: PropTypes.shape({
//     variant: PropTypes.oneOf(["contained", "gradient"]),
//     color: PropTypes.oneOf([
//       "primary",
//       "secondary",
//       "info",
//       "success",
//       "warning",
//       "error",
//       "dark",
//       "light",
//     ]),
//   }),
//   isSorted: PropTypes.bool,
// };
