import axios from "axios";
import { getHeaders } from "utils/helper";
import endpoints from "./endpoints";

export const getUserAndSystemDataApi = async () => {
  const callResponse = await axios({
    url: endpoints.userAndSystemFetchByToken,
    method: "get",
    headers: getHeaders(),
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};


export const getUserApi = async params => {
  const callResponse = await axios({
      url: endpoints.userBase,
      method: "get",
      headers: getHeaders(),
      params,
  })
      .then(response => response.data)
      .catch(err => err.response.data);

  return callResponse;
};

export const deleteUserApi = async data => {
  const callResponse = await axios({
      url: endpoints.userBase,
      method: "DELETE",
      headers: getHeaders(),
      data,
  })
      .then(response => response.data)
      .catch(err => err.response.data);

  return callResponse;
};
export const addUserApi = async data => {
  const callResponse = await axios({
      url: endpoints.userBase,
      method: "POST",
      headers: getHeaders(),
      // headers: { "Content-Type": "multipart/form-data", ...getHeaders() },
      data,
  })
      .then(response => response.data)
      .catch(err => err.response.data);

  return callResponse;
};

export const updateUserField = async data => {
  const callResponse = await axios({
      url: endpoints.userBase,
      method: "PUT",
      headers: getHeaders(),
      data,
  })
      .then(response => response.data)
      .catch(err => err.response.data);

  return callResponse;
};




export default getUserAndSystemDataApi